import { createSelector } from 'reselect'
import moment from 'moment'
import { List } from 'immutable'
import { emptySchedule } from 'redux/schemas'
import { getEntitiesState, getEntities } from './_utils'
import { getEvent, getEventWithOverrides, getBooking, getRequest, inviteScheduleIsBookable } from 'redux/selectors'

export const getSchedules = getEntities('schedules')
export const getArgs = (state, mDate, id) => ({ state, mDate, id })

export const getSchedulesForMonth = createSelector(
  getSchedules,
  (state, mDate, id) => ({ state, mDate, id }),
  (schedules, { state, mDate, id }) =>
    schedules
      .map((value, idDate) => moment.utc(idDate))
      .filter((date) => date.isSame(mDate, 'month'))
      .map((date) => getScheduleForDate(state, date, id))
      .sort((a, b) => moment(a.date).diff(b.date)),
)

export const getSchedule = createSelector(getSchedules, getArgs, (schedules, { mDate }) => {
  if (!mDate || !moment.isMoment(mDate) || !mDate.isValid()) return emptySchedule
  return schedules.get(mDate.format('YYYY-MM-DD'), emptySchedule)
})

function mapEvents(state, eventIds = new List(), eventId = null) {
  return eventIds
    .filter((id) => eventId === null || eventId === id)
    .map((id) => getEvent(state, id))
    .toMap()
    .mapKeys((k, event) => event.id)
}

export const getScheduleForDate = createSelector(getSchedule, getArgs, (schedule, { state, mDate, id }) =>
  schedule
    .setIn(['closed_events'], mapEvents(state, schedule.closed_event_ids, id))
    .setIn(['opened_events'], mapEvents(state, schedule.opened_event_ids, id))
    .setIn(['bookable_events'], mapEvents(state, schedule.bookable_event_ids, id))
    .setIn(['instant_booking_events'], mapEvents(state, schedule.instant_booking_event_ids, id))
    .setIn(['requestable_events'], mapEvents(state, schedule.requestable_event_ids, id))
    .setIn(
      ['bookings'],
      schedule.booking_ids
        .map((bookingId) => getBooking(state, bookingId))
        .filter((booking) => !!booking.id)
        .filter(({ event_id: eventId }) => !id || id === 'all' || id === eventId)
        .toMap()
        .mapKeys((k, booking) => booking.id),
    )
    .setIn(
      ['requests'],
      schedule.request_ids
        .map((requestId) => getRequest(state, requestId))
        .filter(({ event_id: eventId }) => !id || id === 'all' || id === eventId)
        .toMap()
        .mapKeys((k, request) => request.id),
    ),
)

export const hasSchedulesMonthBeenFetched = createSelector(
  getSchedulesForMonth,
  (state) => state,
  (schedules, state) =>
    !!schedules.find((s) => !!(s.closed_events.size || s.opened_events.size || s.bookable_events.size)),
)

export const getSchedulesForEvent = createSelector(
  getSchedules,
  (state, id) => ({ state, id }),
  (schedules, { state, id }) =>
    schedules
      .map((value, idDate) => moment.utc(idDate))
      .map((date) => getScheduleForDate(state, date, id))
      .sort((a, b) => moment(a.date).diff(b.date)),
)

// Selectors for month of schedules for a given event
export const getEventSchedulesOpenedBookableDays = createSelector(
  getSchedulesForMonth,
  getArgs,
  (schedules, { id }) => {
    const openedDates = (acc, el) => {
      if (moment(el.date).isBefore(moment(), 'day')) return acc
      return el.opened_event_ids.includes(id) && el.bookable_event_ids.includes(id) ? [...acc, moment(el.date)] : acc
    }
    return schedules.reduce(openedDates, [])
  },
)

// Selectors for a specific date schedule
export const scheduleIsBookable = createSelector(getSchedule, getArgs, (schedule, { id }) =>
  schedule.get('bookable_event_ids').includes(id),
)

export const scheduleIsOpened = createSelector(getSchedule, getArgs, (schedule, { id }) =>
  schedule.get('opened_event_ids').includes(id),
)

export const scheduleIsBookableAndOpened = createSelector(
  scheduleIsBookable,
  scheduleIsOpened,
  (bookable, opened) => bookable && opened,
)

export const scheduleIsRequestable = createSelector(getSchedule, getArgs, (schedule, { id }) =>
  schedule.get('requestable_event_ids').includes(id),
)

export const scheduleIsInstantBookable = createSelector(getSchedule, getArgs, (schedule, { id }) =>
  schedule.get('instant_booking_event_ids').includes(id),
)

// Selectors for a month of schedules with an event
export const getBookableSchedules = createSelector(
  getSchedulesForEvent,
  (_, id) => id,
  (schedules, id) =>
    schedules
      .filter((schedule) => schedule.bookable_event_ids.includes(id))
      .sort((a, b) => moment(a.date).diff(b.date)),
)

export const getNonBookableSchedules = createSelector(
  getSchedulesForEvent,
  (_, id) => id,
  (schedules, id) =>
    schedules
      .filter((schedule) => !schedule.bookable_event_ids.includes(id))
      .sort((a, b) => moment(a.date).diff(b.date)),
)

export const getOpenedSchedules = createSelector(
  getSchedulesForEvent,
  (_, id) => id,
  (schedules, id) =>
    schedules.filter((schedule) => schedule.opened_event_ids.includes(id)).sort((a, b) => moment(a.date).diff(b.date)),
)

export const getNonOpenedSchedules = createSelector(
  getSchedulesForEvent,
  (_, id) => id,
  (schedules, id) =>
    schedules.filter((schedule) => !schedule.opened_event_ids.includes(id)).sort((a, b) => moment(a.date).diff(b.date)),
)

export const getInstantBookingSchedules = createSelector(
  getSchedulesForEvent,
  (_, id) => id,
  (schedules, id) =>
    schedules
      .filter((schedule) => schedule.instant_booking_event_ids.includes(id))
      .sort((a, b) => moment(a.date).diff(b.date)),
)

export const getOpenBookableSchedules = createSelector(getBookableSchedules, getOpenedSchedules, (bookable, opened) =>
  bookable.filter((_, key) => opened.has(key)).sort((a, b) => moment(a.date).diff(b.date)),
)

export const getNonOpenBookableSchedules = createSelector(
  getSchedulesForEvent,
  getOpenBookableSchedules,
  (schedules, openedBookable) =>
    schedules.filter((_, key) => !openedBookable.has(key)).sort((a, b) => moment(a.date).diff(b.date)),
)

export const getSoldOutSchedules = createSelector(
  getOpenedSchedules,
  (_, id) => id,
  (state) => state,
  (opened, id, state) => {
    return opened
      .filter((schedule) => getScheduleEventBookingSeatsLeft(state, moment.utc(schedule.date), id) === 0)
      .sort((a, b) => moment(a.date).diff(b.date))
  },
)

export const getScheduleBookings = createSelector(getScheduleForDate, (schedule) => schedule.bookings)

export const getSchedulesNextOpenDates = createSelector(
  getSchedules,
  (_state, id) => id,
  (_state, _id, startDateRange) => startDateRange,
  (schedules, id, startDateRange) => {
    const endDateRange = startDateRange && moment(startDateRange).add(7, 'days')
    const bookableDates = schedules
      .sort((a, b) => moment(a.date).diff(b.date))
      .filter((s) => {
        const isBookable = s.bookable_event_ids.includes(id)
        if (!startDateRange) return isBookable
        return isBookable && moment.utc(s.date).isBetween(startDateRange, endDateRange, 'days', '[]')
      })

    const openDates = bookableDates.filter((s) => s.opened_event_ids.includes(id)).slice(0, 5)
    return openDates.size > 0 ? openDates : bookableDates.slice(0, 5)
  },
)

export const getSchedulesFirstOpenDate = createSelector(getOpenBookableSchedules, (schedules) => {
  const schedule = schedules.first() || emptySchedule
  return schedule.date ? moment.utc(schedule.date) : undefined
})

const getScheduleBookingSeatsLeft = createSelector(
  getScheduleBookings,
  (state, mDate, id) => getEventWithOverrides(state, id, mDate.format('YYYY-MM-DD')),
  getArgs,
  (bookings, event, { mDate, id }) => {
    const result = bookings.reduce(
      (seats, b) => (b.groupStatus === 'successful' && b.event_id === id ? seats - b.seats : seats),
      event.max_seats,
    )
    return result > 0 ? result : 0
  },
)

export const getScheduleEventBookingSeatsLeft = (state, mDate, id) =>
  !mDate || !mDate.isValid() ? getEvent(state, id).max_seats || 0 : getScheduleBookingSeatsLeft(state, mDate, id)

const getScheduleBookingSeatsBooked = createSelector(
  getScheduleBookings,
  (state, mDate, id) => getEvent(state, id),
  getArgs,
  (bookings, event, { mDate, id }) =>
    bookings.reduce((seats, b) => (b.groupStatus === 'successful' && b.event_id === id ? seats + b.seats : seats), 0),
)

export const getScheduleEventBookingSeatsBooked = (state, mDate, id) =>
  !mDate || !mDate.isValid() ? 0 : getScheduleBookingSeatsBooked(state, mDate, id)

export const scheduleIsSoldOut = createSelector(
  scheduleIsOpened,
  getScheduleEventBookingSeatsLeft,
  (opened, seatsLeft) => opened && seatsLeft === 0,
)

// Args: id, date
export const isEventInstantlyBookable = createSelector(
  getEventWithOverrides,
  inviteScheduleIsBookable,
  (event, inviteScheduleIsBookable) => event.instant_booking || event.privatized_by || inviteScheduleIsBookable,
)

// loading
export const fetchingSchedules = (state) =>
  !!getEntitiesState(state)
    .get('loading')
    .find((url) => url.includes('/planning'))

import { createGlobalStyle } from 'styled-components'

// Resets & Basics, from Bootstrap
const lineHeightBase = 1.428571429
const fontSizeBase = 16
const fontSizeSmall = 14
const fontSizeH1 = Math.floor(fontSizeBase * 2.6)
const fontSizeH2 = Math.floor(fontSizeBase * 2.15)
const fontSizeH3 = Math.ceil(fontSizeBase * 1.7)
const fontSizeH4 = Math.ceil(fontSizeBase * 1.25)
const fontSizeH5 = fontSizeBase
const fontSizeH6 = Math.ceil(fontSizeBase * 0.85)
const lineHeightComputed = Math.floor(fontSizeBase * lineHeightBase)

export const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  // RESETS

  html {
    position: relative;
    height: 100%;
    min-height: 100%;
    overflow-y: auto;
    overscroll-behavior: none;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    ${(props) => props.theme.media.tablet`
      overflow-y: scroll;
    `}
  }

  body {
    font-family: ${({ theme }) => theme.fonts.text};
    color: ${({ theme }) => theme.colors.title};
    background-color: ${({ theme }) => theme.colors.white};
    margin: 0;
    font-size: ${fontSizeBase}px;
    line-height: ${lineHeightBase};
    background-color: #fff;
    max-height: none !important; // override the style injected by intercom banner
  }

  // Reset fonts for relevant elements
  input,
  button,
  select,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }

  audio,
  canvas,
  progress,
  video {
    display: inline-block; // 1
    vertical-align: baseline; // 2
  }

  audio:not([controls]) {
    display: none;
    height: 0;
  }

  [hidden],
  template {
    display: none;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;

    &:hover,
    &:focus {
      outline: 0;
      color: #ff8b55; // darken(@primaryColor, 5%);
      text-decoration: none;
    }
  }

  abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
  }

  b,
  strong {
    font-weight: bold;
  }

  dfn {
    font-style: italic;
  }


  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }

  mark {
    background: #ff0;
    color: #000;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  img {
    border: 0;
    vertical-align: middle;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  figure {
    margin: 1em 40px;
  }


  hr {
    box-sizing: content-box;
    height: 0;
    color: ${({ theme }) => theme.colors.lightGray};
    margin-top: ${lineHeightComputed}px;
    margin-bottom: ${lineHeightComputed}px;
    border: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
  }

  pre {
    overflow: auto;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit; // 1
    font: inherit; // 2
    margin: 0; // 3
  }

  button {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }

  button[disabled],
  html input[disabled] {
    cursor: default;
  }

  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  input {
    line-height: normal;
  }

  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  input[type="search"] {
    -webkit-appearance: textfield;
    box-sizing: content-box;
  }

  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  input:required {
    box-shadow: none;
  }

  fieldset {
    border: 1px solid ${({ theme }) => theme.colors.lightGray};
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
  }

  legend {
    border: 0;
    padding: 0;
  }

  textarea {
    overflow: auto;
  }

  optgroup {
    font-weight: bold;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  td,
  th {
    padding: 0;
  }

  // TYPE

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {

    font-weight: 500;
    line-height: 1.1;
    color: inherit;

    small {
      font-weight: 400;
      line-height: 1;
    }
  }

  h1,
  h2,
  h3 {
    margin-top: ${lineHeightComputed}px;
    margin-bottom: ${lineHeightComputed / 2}px;

    small {
      font-size: 65%;
    }
  }

  h4,
  h5,
  h6 {
    margin-top: ${lineHeightComputed / 2}px;
    margin-bottom: ${lineHeightComputed / 2}px;

    small {
      font-size: 75%;
    }
  }

  h1 {
    font-size: ${fontSizeH1}px;
  }

  h2 {
    font-size: ${fontSizeH2}px;
  }

  h3 {
    font-size: ${fontSizeH3}px;
  }

  h4 {
    font-size: ${fontSizeH4}px;
  }

  h5 {
    font-size: ${fontSizeH5}px;
  }

  h6 {
    font-size: ${fontSizeH6}px;
  }

  p {
    margin: 0 0 ${lineHeightComputed / 2}px;
  }

  small {
    font-size: ${Math.floor(fontSizeSmall / fontSizeBase)}%;
  }

  ul,
  ol {
    margin-top: 0;
    margin-bottom: ${lineHeightComputed / 2}px;

    ul,
    ol {
      margin-bottom: 0;
    }
  }

  blockquote {
    padding: ${lineHeightComputed / 2}px ${lineHeightComputed}px;
    margin: 0 0 ${lineHeightComputed}px;
    font-size: 20px;
    border-left: 5px solid ${({ theme }) => theme.colors.lightGray};

    p,
    ul,
    ol {
      &:last-child {
        margin-bottom: 0;
      }
    }

    footer,
    small {
      display: block;
      font-size: 80%; // back to default font-size
      line-height: ${lineHeightBase};
      color: inherit;

      &:before {
        content: "\2014 \00A0"; // em dash, nbsp
      }
    }
  }

  // @see https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    select,
    textarea,
    input {
      font-size: 16px !important;
    }
  }
`

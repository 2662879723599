import { schema } from 'normalizr'
import { Record, List, Map, fromJS } from 'immutable'

import { CurrencySchema } from './currencies'
import { FileSchema } from './files'
import { PlaceSchema } from './places'
import { OverrideSchema } from './overrides'

export const EventSchema = new schema.Entity('events', {
  cover: FileSchema,
  currency: CurrencySchema,
  event_currency: CurrencySchema,
  files: [FileSchema],
  place: PlaceSchema,
  overrides: OverrideSchema,
  // user schema is linked in ./_utils.js due to circular dependencies
})

export const EventRecord = Record({
  available_for_partners: undefined,
  begins_at: '',
  booking_deadline: undefined,
  cancellation_deadline: undefined,
  can_have_instant_bookings: undefined,
  created_at: undefined,
  cover: undefined,
  cover_id: undefined,
  currency: undefined,
  currency_id: undefined,
  description: '',
  descriptions: List(),
  duration: undefined,
  ends_at: '',
  event_currency: undefined,
  event_currency_id: undefined,
  event_price: undefined,
  files: List(),
  files_ids: List(),
  frozen_at: undefined,
  hosted_with: '',
  id: undefined,
  instant_booking: undefined,
  max_seats: undefined,
  masterchef_us: undefined,
  pe_booking_deadline: undefined,
  pe_cancellation_deadline: undefined,
  tags: List([]),
  metadata: fromJS({
    euro_pricing: {
      event_price: undefined,
    },
  }),
  next_date: Map({
    date: undefined,
    available_seats: undefined,
    range_status: undefined,
  }),
  min_seats: undefined,
  overrides: undefined,
  price: undefined,
  place: undefined,
  place_id: undefined,
  allow_private_bookings: undefined,
  allow_public_bookings: undefined,
  privatized_by: undefined,
  properties: Map(),
  published_at: undefined,
  status: '',
  title: '',
  type: '',
  localized_type: '',
  user: undefined,
  user_id: undefined,
  webpage_url: '',
  parent_id: undefined,
})

export const emptyEvent = new EventRecord()

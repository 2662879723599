import isUndefined from 'lodash/isUndefined'

function isStringBool(string) {
  return string === 'false' || string === 'true'
}

function getEnvVar(parsedEnvVar, defaultValue) {
  if (parsedEnvVar && typeof parsedEnvVar === 'string' && typeof parsedEnvVar.trim === 'function') {
    const envVar = parsedEnvVar.trim()
    return isStringBool(envVar) ? envVar === 'true' : envVar
  }
  if (!isUndefined(defaultValue)) return defaultValue
}

/**
 * TODO: After the migration to next is done, env vars should be consumed directly and I believe they can still be
 * further simplified to remove duplicates. Some challenges remain like
 *   - how to parse booleans
 *   - how to parse numbers
 *   - how to replace functions, arrays, objects, etc
 * Another simpler approach is to be aware all env variables are string and hence parsing them is always a requirement
 */
const settings = {
  // Client side access to API
  screenApiUrl: getEnvVar(process.env.NEXT_PUBLIC_SCREEN_API_URL),
  fileUrl: getEnvVar(process.env.NEXT_PUBLIC_FILE_URL),
  blogUrl: getEnvVar(process.env.NEXT_PUBLIC_BLOG_URL),
  widgetScriptUrl: getEnvVar(process.env.NEXT_PUBLIC_WIDGET_SCRIPT_URL),
  screenApiVersion: getEnvVar(process.env.NEXT_PUBLIC_SCREEN_API_VERSION),
  cloudFlareWorkersApi: getEnvVar(process.env.NEXT_PUBLIC_CLOUDFLARE_WORKERS_API),
  domainName: getEnvVar(process.env.NEXT_PUBLIC_DOMAIN_NAME),
  cookieDomainName: getEnvVar(process.env.NEXT_PUBLIC_COOKIE_DOMAIN_NAME),
  vercelEnv: getEnvVar(process.env.NEXT_PUBLIC_VERCEL_ENV),

  // axios config
  axiosCacheHeadersDisabled: getEnvVar(process.env.NEXT_PUBLIC_AXIOS_CACHE_HEADERS_DISABLED),

  // Geo
  geo: {
    autocomplete: getEnvVar(process.env.NEXT_PUBLIC_GEO_AUTOCOMPLETE_URL),
    geocode: getEnvVar(process.env.NEXT_PUBLIC_GEO_GEOCODE_URL),
    place: getEnvVar(process.env.NEXT_PUBLIC_GEO_PLACE_URL),
    reverse: getEnvVar(process.env.NEXT_PUBLIC_GEO_REVERSE_URL),
  },

  // Google
  googleClientId: getEnvVar(process.env.NEXT_PUBLIC_GOOGLE_API_CLIENT),
  // Consent
  isConsentActive: getEnvVar(process.env.NEXT_PUBLIC_CONSENT_IS_ACTIVE),
  consentBannerUrl: getEnvVar(process.env.NEXT_PUBLIC_CONSENT_BANNER_URL),
  // Stripe
  stripePublicKey: getEnvVar(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY),
  // Intercom
  intercomApiId: getEnvVar(process.env.NEXT_PUBLIC_INTERCOM_API_ID),
  isIntercomActive: getEnvVar(process.env.NEXT_PUBLIC_INTERCOM_IS_ACTIVE),
  // Facebook
  facebookAppId: getEnvVar(process.env.NEXT_PUBLIC_FACEBOOK_APP_ID),
  iMetaPixelActive: getEnvVar(process.env.NEXT_PUBLIC_IS_META_PIXEL_ACTIVE),
  // Awin
  isAwinTrackingActive: getEnvVar(process.env.NEXT_PUBLIC_AWIN_IS_TRACKING_ACTIVE),
  // TripAdvisor
  isTripAdvisorPixelActive: getEnvVar(process.env.NEXT_PUBLIC_TRIPADVISOR_IS_PIXEL_ACTIVE),
  tripAdvisorPixelUrl: getEnvVar(process.env.NEXT_PUBLIC_TRIPADVISOR_PIXEL_URL),
  // InLinks
  isInLinksActive: getEnvVar(process.env.NEXT_PUBLIC_INLINK_IS_ACTIVE),
  inLinksPID: getEnvVar(process.env.NEXT_PUBLIC_INLINK_PID),
  // Growthbook
  growthbookUrl: getEnvVar(process.env.NEXT_PUBLIC_GROWTHBOOK_URL),
  isGrowthbookActive: getEnvVar(process.env.NEXT_PUBLIC_GROWTHBOOK_IS_ACTIVE),
  mailchimpAudienceId: getEnvVar(process.env.NEXT_PUBLIC_MAILCHIMP_AUDIENCE_ID),
  // Campaigns
  isGiftCardCampaignActive: getEnvVar(process.env.NEXT_PUBLIC_IS_GIFT_CARD_CAMPAIGN_ACTIVE),
}

export default settings

import { createSelector } from 'reselect'
import { getEntities, getEntity, isFetching, isCreating, getFetchError } from './_utils'
import { getOverride, getOverrideId, getBooking, getRequest, getUser } from 'redux/selectors'
import { emptyInvite } from 'redux/schemas/invites'
import { List } from 'immutable'

export const getInvites = getEntities('invites')
export const getInvite = getEntity('invites')
export const fetchingInvite = (state, token) => isFetching(`/invites/${token}`)(state)
export const creatingInvite = isCreating('/invites')
export const getFetchInviteError = (state, token) => getFetchError(`/invites/${token}`)(state)
export const getInviteOverride = createSelector(
  getInvite,
  (state) => state,
  (invite, state) => getOverride(state, getOverrideId(invite.event_id, invite.date)),
)

export const getInviteBookings = createSelector(
  getInvite,
  (state) => state,
  (invite, state) => (invite.bookings ? invite.bookings.map((id) => getBooking(state, id)) : new List()),
)

export const getInviteRequest = createSelector(
  getInvite,
  (state) => state,
  (invite, state) => getRequest(state, invite.request_id),
)

export const getInviteRequestUser = createSelector(
  getInviteRequest,
  (state) => state,
  (request, state) => getUser(state, request.user_id),
)

export const getInviteForEventAndDate = createSelector(
  getInvites,
  (_, eventId, date) => ({ eventId, date }),
  (invites, { eventId, date }) =>
    invites.find((invite) => invite.event_id === parseInt(eventId, 10) && invite.date === date, null, emptyInvite),
)

export const inviteScheduleIsBookable = createSelector(
  getInviteForEventAndDate,
  (_, eventId) => ({ eventId }),
  (invite, { eventId }) =>
    invite.schedule ? invite.schedule.get('bookable_event_ids', new List()).includes(eventId) : false,
)

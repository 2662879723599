import { useQuery } from '@tanstack/react-query'
import { getIPGeolocation } from 'api/IPGeolocation'
import { STALE_TIME } from 'helpers/query'
import { emptyIPGeolocation } from 'schemas/IPGeolocation'

export function useIPGeolocationQuery(reactQueryOptions) {
  return useQuery({
    staleTime: STALE_TIME,
    placeholderData: emptyIPGeolocation,
    queryKey: ['IPGeolocation'],
    queryFn: () => getIPGeolocation(),
    ...reactQueryOptions,
  })
}

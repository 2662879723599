import { schema } from 'normalizr'
import { Record, List, Set, Map } from 'immutable'

import { AccountSchema } from './accounts'
import { FileSchema } from './files'
import { CurrencySchema } from './currencies'
import { LanguageSchema } from './languages'
import { PartnerSchema } from './partners'

export const UserSchema = new schema.Entity('users', {
  account: AccountSchema,
  avatar: FileSchema,
  currency: CurrencySchema,
  files: [FileSchema],
  languages: [LanguageSchema],
  partner: PartnerSchema,
  // event schema is linked in ./_utils.js due to circular dependencies
})

export const UserRecord = Record({
  id: undefined,

  account: undefined,
  account_id: undefined,
  age: '',
  avatar: undefined,
  avatar_id: undefined,
  birth_date: '',
  civility: '',
  country: undefined,
  country_iso: '',
  currency: undefined,
  currency_id: undefined,
  description: '',
  events: new List(),
  files: new Set(),
  firstname: '',
  languages: new List(),
  lastname: '',
  partner: undefined,
  phone: undefined,
  locality: undefined,
  properties: new Map(),
  rating: new Map(),
  tags: List([]),
  published_review_count: '',

  created_at: undefined,
  updated_at: undefined,
})

export const emptyUser = new UserRecord()

import { useEffect } from 'react'

export function usePreventBodyScroll(isActive = true) {
  useEffect(() => {
    if (isActive) {
      document.body.style.overflow = 'hidden'
      document.body.style.position = 'fixed'

      return function cleanUp() {
        document.body.style.overflow = null
        document.body.style.position = null
      }
    }
  }, [isActive])
}

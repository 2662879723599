import { Record, Map } from 'immutable'

/**
 * The ReservationRecord is an abstraction over RequestRecord & BookingRecord
 * to be used whenever we expect one or the other and
 * the shared properties are the main focus
 * @type {ReservationRecord}
 */
export const ReservationRecord = Record({
  id: undefined,
  additional_info: undefined,
  begins_at: undefined,
  booking_id: undefined,
  canceled_by: undefined,
  conversation_id: undefined,
  created_at: undefined,
  date: undefined,
  ends_at: undefined,
  event_id: undefined,
  guest_already_left_review: false,
  host_already_left_review: false,
  invite_token: undefined,
  occasion: undefined,
  payment: new Map(),
  privatized_at: undefined,
  privatized_by: undefined,
  request_id: undefined,
  seats: undefined,
  status: undefined,
  updated_at: undefined,
  user_id: undefined,
})

export const emptyReservation = new ReservationRecord()

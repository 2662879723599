import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { appWithTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useIsRtl } from 'hooks/locales/useIsRtl'
import { useLanguage } from 'hooks/languages/useLanguage'
import { usePrevious } from 'hooks'
import { CurrencyProvider } from 'hooks/currencies/useCurrencyContext'
// Redux
import { Provider } from 'react-redux'
import { wrapper } from 'redux/store'
// Layout
import { AppLayout } from 'layouts/AppLayout'
import { ApplicationSEO } from 'components/shared/ApplicationSEO'
// Style
import { ThemeProvider, StyleSheetManager } from 'styled-components'
import stylisRTLPlugin from 'stylis-plugin-rtl'
import { eatwithTheme } from '@vizeat/components/es6/theme'
import { GlobalStyle } from '../styles/globalStyle'
import '../styles/fontStyle.css'
// GTM
import { gtmPageView } from 'gtm'
// Helpers
import settings from 'settings'
import { screenApi } from 'apis'
import { konstants } from '@vizeat/helpers'
import { COOKIE_KEYS, loadCookie } from 'helpers/cookies'
// Providers
import AppGrowthBookProvider from 'providers/growthbook'
import { AppLoader } from 'components/AppLoader'

// eslint-disable-next-line react/prop-types
function MyApp({ Component, pageProps, err }) {
  const router = useRouter()
  const { locale } = router
  const prevLocale = usePrevious(locale)
  const cookieLocale = loadCookie(COOKIE_KEYS.EW_LANGUAGE)
  const { setAppLanguage } = useLanguage()
  const { isRTL } = useIsRtl()
  const gtmPageViewTimerIdRef = useRef(0)
  const { store: reduxStore } = wrapper.useWrappedStore({ pageProps })

  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: { refetchOnMount: false, refetchOnWindowFocus: false, refetchOnReconnect: false, retry: false },
        },
      }),
  )

  screenApi.setCurrencyHeader(loadCookie(COOKIE_KEYS.EW_CURRENCY) || konstants.DEFAULT_CURRENCY.iso_3)
  screenApi.setLanguageHeader(locale)

  useEffect(() => {
    router.events.on('routeChangeComplete', gtmPageView)
    return () => {
      router.events.off('routeChangeComplete', gtmPageView)
    }
  }, [router.events])

  const consentBannerEnv = useMemo(() => {
    if (['ios', 'android'].includes(router.query.platform)) return `${router.query.platform}_production`
    return process.env.NODE_ENV === 'production' ? 'production' : 'staging'
  }, [router.query.platform])

  const initConsent = useCallback(() => {
    window.ewConsent?.init({
      locale,
      env: consentBannerEnv,
    })

    // random delay until gtm is initialized
    gtmPageViewTimerIdRef.current = setTimeout(() => {
      gtmPageView(router.pathname)
    }, 1000)
  }, [consentBannerEnv, locale, router.pathname])

  useEffect(
    () => () => {
      clearTimeout(gtmPageViewTimerIdRef.current)
    },
    [],
  )
  useEffect(() => {
    if (prevLocale && prevLocale !== locale) {
      initConsent()
    }
  }, [initConsent, locale, prevLocale])

  useEffect(() => {
    if (!prevLocale && cookieLocale && cookieLocale !== locale) setAppLanguage(cookieLocale)
  }, [cookieLocale, locale, prevLocale, setAppLanguage])

  return (
    <>
      <Script
        strategy='beforeInteractive'
        src={`https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?features=es2015,es2016,es2017,es2018,es5,es6,es7,Object.fromEntries,Object.entries,Array.prototype.flat,IntersectionObserver,Intl,Intl.~locale.${locale},Intl.PluralRules,Intl.Locale,Intl.PluralRules.~locale.${locale},Intl.RelativeTimeFormat,Intl.RelativeTimeFormat.~locale.${locale},default,String.prototype.at`}
      />
      {/* Prevent using Prismic preview toolbar in production since it's not used */}
      {process.env.NODE_ENV !== 'production' && (
        <Script strategy='afterInteractive' src='https://static.cdn.prismic.io/prismic.js?new=true&repo=eatwith' />
      )}

      {settings.isConsentActive && (
        <Script strategy='lazyOnload' src={settings.consentBannerUrl} onLoad={initConsent} />
      )}

      <ThemeProvider theme={eatwithTheme}>
        <GlobalStyle />
        <AppLoader />
        <StyleSheetManager stylisPlugins={isRTL ? [stylisRTLPlugin] : []}>
          <CurrencyProvider>
            <QueryClientProvider client={queryClient}>
              <ApplicationSEO />
              {/* eslint-disable-next-line react/prop-types */}
              <Hydrate state={pageProps.dehydratedState}>
                {process.env.NODE_ENV !== 'production' && <ReactQueryDevtools initialIsOpen={false} />}
                <Provider store={reduxStore}>
                  <AppGrowthBookProvider>
                    <AppLayout>
                      {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
                      <Component {...pageProps} err={err} />
                    </AppLayout>
                  </AppGrowthBookProvider>
                </Provider>
              </Hydrate>
            </QueryClientProvider>
          </CurrencyProvider>
        </StyleSheetManager>
      </ThemeProvider>
    </>
  )
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext) => {
// calls page's `getInitialProps` and fills `appProps.pageProps`
// const appProps = await App.getInitialProps(appContext)

//   return { ...appProps }
// }

export default appWithTranslation(MyApp)

import settings from 'settings'
import { hash } from 'helpers/crypto'
import { ensureJSContent, getEventTitle } from 'helpers/legacy'
import { numbers } from '@vizeat/helpers'
import { COOKIE_KEYS, loadCookie } from 'helpers/cookies'

const { formatPriceAsFloat } = numbers

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// Types
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

export const GTM_EVENT_TYPES = {
  CAMPAIGN: 'Campaign',
  EXPERIENCE: 'Experience Page',
  EXPERIENCE_SUGGESTED: 'Experience page suggested products',
  EXPERIENCE_LINKED_TO_REVIEW: 'Experience linked to review',
  FEATURED: 'Featured Event Card',
  HOME_LAST_VIEWED: 'Home page last viewed experiences',
  HOME: 'Home Page',
  HOME_CURATED: 'Home-Curated',
  HOME_TOP_DESTINATIONS: 'Home-TopDestinations',
  HOME_TOP_SELLERS: 'Home-TopSellers',
  HOME_IN_PERSON_EXPERIENCES: 'Home-InPersonExperiences',
  HOME_USER_IP_COUNTRY: 'Home-UserIpCountry',
  MERCHANDISE: 'Merchandise',
  MY_MEALS: 'My Meals',
  PE_CAMPAIGN: 'Private Events Campaign',
  SEARCH_OPEN: 'Upcoming Events',
  SEARCH_PE: 'Private Event Search',
  SEARCH_REQUEST: 'Available by Request',
  SEARCH: 'Search Results',
  SIMILAR_EVENTS: 'Similar Events',
  USER: 'User Page',
  WISHLIST: 'Wishlist',
}

export const GTM_REQUEST_TYPES = {
  REQUEST_INFO: 'Request Information',
  REQUEST_DATE: 'Request a Date',
  PE_REQUEST: 'Private Event Request',
}

export const GTM_FORM_TYPES = {
  ONBOARDING: 'upcoming-trip',
  EXIT_MODAL: 'exit-pop-up',
  SHARE_EVENT_DATES: 'event-sharing-dates-as-message',
  PE_LEAD_MODAL: 'pe-lead-modal',
  GIFT_CARD: 'gift-card',
  PRISMIC_EMAIL_LEAD_SLICE: 'prismic-email-lead-slice',
}

export const GTM_DATE_AVAILABILITY_TYPES = {
  REQUESTABLE: 'requestable',
  SOLD_OUT: 'sold-out',
  BOOKABLE: 'bookable',
}

function replaceSpacesWithUnderscores(name) {
  return name?.toLowerCase().replace(/\s/g, '_')
}

function getGAUserFromLocalStorage() {
  if (typeof window === 'undefined') return null
  // not the most elegant way but we need to share the user across
  // serval gtm call.
  // The next refacto will be to wrap the gtm calls in custom hooks
  try {
    const gaUser = window.localStorage.getItem('gaUser')
    return gaUser ? JSON.parse(gaUser) : null
  } catch (e) {
    return null
  }
}

export async function getUserPayload(user, isNewCustomer) {
  if (!user?.id) return null
  const email = await hash(user.email)
  return {
    visitorLoginState: 'logged',
    visitorId: user.id,
    visitorGender: user.civility === 'MRS' ? 'F' : user.civility === 'MR' ? 'M' : 'Other',
    country: user.country,
    newCustomer: isNewCustomer ? 'yes' : 'no',
    email,
  }
}

function handleGtmEvent(payload) {
  const p = { ...payload }
  const isGlobalEvent = payload.event === 'eventGA'
  const isPageViewEvent = payload.event === 'pageView'
  const gaUser = getGAUserFromLocalStorage()

  if ((isPageViewEvent || isGlobalEvent) && gaUser) {
    p.user = gaUser
  }

  if (!settings.isConsentActive && process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    return console.log(`🚨  GTM event pushed`, p)
  }

  return (window.dataLayer || []).push(p)
}

function getPriceFromEventMetadata(metadata) {
  if (!metadata?.euro_pricing) return
  return (metadata.euro_pricing.event_price || metadata.euro_pricing) / 100
}

function getProductItem({ event, index = 0, price, type = '', quantity = 1, category = '' }) {
  const categories = category?.split('/').reduce((acc, cat, index) => {
    return {
      ...acc,
      [index === 0 ? 'item_category' : `item_category${index + 1}`]: cat,
    }
  }, {})

  return {
    index,
    item_id: String(event.id),
    item_name: getEventTitle('en', event),
    item_brand: String(event.user_id || event.user?.id),
    item_list_name: type,
    item_list_id: replaceSpacesWithUnderscores(type),
    price: price || getPriceFromEventMetadata(event.metadata),
    quantity,
    ...categories,
  }
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// Global tracking
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

export function gtmPageView(url) {
  handleGtmEvent({ event: 'pageView', page: url })
}

export function gtmLanguageCurrency(locale, currency) {
  if (!locale || !currency) return
  const formattedLocale = locale.replace('-', '').toUpperCase()
  const formattedCurrncy = currency.toUpperCase()
  handleGtmEvent({ langCurrency: `${formattedLocale}${formattedCurrncy}` })
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// Form tracking
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

export function gtmFormInitiated(name, data) {
  handleGtmEvent({
    event: 'eventGA',
    eventCategory: 'Form',
    eventAction: 'form initiated',
    eventLabel: JSON.stringify({ formId: name, ...data }),
  })
}

export function gtmFormFieldFilled(name, data) {
  handleGtmEvent({
    event: 'eventGA',
    eventCategory: 'Form',
    eventAction: 'field filled',
    eventLabel: JSON.stringify({ formId: name, ...data }),
  })
}

export function gtmFormAborted(name, data) {
  handleGtmEvent({
    event: 'eventGA',
    eventCategory: 'Form',
    eventAction: 'form aborted',
    eventLabel: JSON.stringify({ formId: name, ...data }),
  })
}

export function gtmFormSent(name, data) {
  handleGtmEvent({
    event: 'eventGA',
    eventCategory: 'Form',
    eventAction: 'form sent',
    eventLabel: JSON.stringify({ formId: name, ...data }),
  })
}

export function gtmGiftCardEvent(action, amount) {
  handleGtmEvent({
    event: 'eventGA',
    eventCategory: 'gift-card',
    eventAction: action,
    eventLabel: `chosen_amount: ${amount}`,
  })
}

export function gtmButtonClicked(data) {
  handleGtmEvent({
    event: 'eventGA',
    eventCategory: 'Button',
    eventAction: 'clicked',
    eventLabel: JSON.stringify(data),
  })
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// Account tracking
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

export function gtmVisitorSawAuthenticationForm({ type, appearance }) {
  handleGtmEvent({
    event: 'eventGA',
    eventCategory: 'user',
    eventAction: 'authentication form',
    eventLabel: `type:${type}, appearance:${appearance}`,
  })
}

export function gtmUserLoggedIn({ user, type: loginType }) {
  // loginType is 'Email' or 'Facebook' or 'Google'
  handleGtmEvent({
    user,
    ...(loginType && {
      // if type of login is defined, fill also the event category
      event: 'eventGA',
      eventCategory: 'user',
      eventAction: 'log in',
      eventLabel: loginType,
    }),
  })
}

export function gtmUserLoggedOut() {
  handleGtmEvent({ user: { visitorLoginState: 'unlogged', email: undefined } })
}

export function gtmRegistered({ user, type }) {
  //  registerType is 'Email' or 'Facebook' or 'Google'
  handleGtmEvent({
    user,
    event: 'eventGA',
    eventCategory: 'user',
    eventAction: 'registration',
    eventLabel: type,
  })
  handleGtmEvent({ event: 'socialRegistration' })
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// Account tracking
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

export function gtmEventListDisplayed({ type = '', events = [], currency = {}, pushEcommerceEvent = handleGtmEvent }) {
  const eventsInJS = ensureJSContent(events)
  if (eventsInJS.length === 0) return

  const impressions = eventsInJS.reduce(
    (acc, immutableEvent, index) => {
      const event = ensureJSContent(immutableEvent)
      const locality = event.place?.locality
      const country = event.place?.country

      const category = locality && country ? `${country}/${locality}` : undefined
      acc.ga4.push(getProductItem({ type, event, index, category }))
      return acc
    },
    { ga4: [], ua: [] },
  )

  pushEcommerceEvent({
    event: 'impression.productView',
    ecommerce: { currencyCode: currency.iso_3, impressions: impressions.ua },
  })

  pushEcommerceEvent({
    event: 'view_item_list',
    ecommerce: {
      item_list_id: replaceSpacesWithUnderscores(type),
      item_list_name: type,
      items: impressions.ga4,
    },
  })
}

export function gtmSearchResultsDisplayed(eventsDisplayedCount = 0, total = 0) {
  handleGtmEvent({ searchResultsPage: eventsDisplayedCount, searchResultsTotal: total })
}

export function gtmFilterSelected(name, value) {
  handleGtmEvent({ event: 'eventGA', eventCategory: 'search', eventAction: name, eventLabel: value })
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// Request tracking
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

export function gtmContactedHost(type, eventId, requestId) {
  handleGtmEvent({
    event: 'eventGA',
    eventCategory: 'request',
    eventAction: type,
    eventLabel: `event: ${eventId}, request: ${requestId}`,
  })
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// Event tracking
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

export function gtmEventClicked({
  type,
  place,
  event: providedEvent,
  position = -1,
  pushEcommerceEvent = handleGtmEvent,
}) {
  if (!providedEvent || !place) return
  const event = ensureJSContent(providedEvent)

  const payload = {
    event,
    category: `${place.country}/${place.locality}`,
    index: position,
    type,
  }

  pushEcommerceEvent({
    event: 'select_item',
    ecommerce: {
      item_list_id: replaceSpacesWithUnderscores(type),
      item_list_name: type,
      items: [getProductItem(payload)],
    },
  })
}

// TODO: too risky refactor to allow passing isInstant, hasGuests and availability because it would require changing too many function signatures...
export function gtmEventDaySelected({ availability, date, eventId, hasGuest, isInstant, placement, userId }) {
  handleGtmEvent({
    event: 'eventGA',
    eventCategory: 'Button',
    eventAction: 'clicked',
    eventLabel: JSON.stringify({
      availability,
      buttonId: 'pickAnEventDate',
      date,
      eventId,
      hasGuest,
      isInstant,
      placement,
      userId,
    }),
  })
}

function getEcommercePayload({ event, pricing, place, quantity }) {
  if (!pricing || !event || !place) return null
  return {
    event,
    price: pricing.metadata.euro_pricing.event_price / 100,
    category: `${place.country}/${place.locality}`,
    quantity,
  }
}

export function gtmEcommerce({ event, place, pricing, pushEcommerceEvent = handleGtmEvent }) {
  const payload = getEcommercePayload({ event, pricing, place })
  if (!payload) return

  pushEcommerceEvent({
    event: 'view_item',
    ecommerce: {
      currency: 'EUR',
      value: payload.price,
      items: [getProductItem(payload)],
    },
  })
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// Event tracking
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

export function gtmCheckout({ event, place, pricing, pushEcommerceEvent = handleGtmEvent }) {
  const payload = getEcommercePayload({ event, pricing, place, quantity: pricing.payer.seats })
  if (!payload) return

  pushEcommerceEvent({
    event: 'begin_checkout',
    ecommerce: {
      currency: 'EUR',
      value: payload.price,
      coupon: pricing.campaign?.code || '',
      items: [getProductItem(payload)],
    },
  })
}

export function gtmAddToCart({ event, place, pricing, seatsDifference, pushEcommerceEvent = handleGtmEvent }) {
  const payload = getEcommercePayload({ event, pricing, place, quantity: seatsDifference })
  if (!payload) return

  pushEcommerceEvent({
    event: 'add_to_cart',
    ecommerce: {
      value: payload.price,
      currency: 'EUR',
      items: [getProductItem(payload)],
    },
  })
}

export function gtmRemoveFromCart({ event, place, pricing, seatsDifference, pushEcommerceEvent = handleGtmEvent }) {
  const payload = getEcommercePayload({ event, pricing, place, quantity: seatsDifference })
  if (!payload) return

  pushEcommerceEvent({
    event: 'remove_from_cart',
    ecommerce: {
      value: payload.price,
      currency: 'EUR',
      items: [getProductItem(payload)],
    },
  })
}

export function gtmAvailablePaymentMethods(availableMethods) {
  handleGtmEvent({
    event: 'eventGA',
    eventCategory: 'checkout-payment-methods',
    eventAction: 'available methods shown',
    eventLabel: JSON.stringify({ availableMethods }),
  })
}

export function gtmPurchased({
  booking,
  event,
  place,
  pricing,
  paymentMethodsInformation,
  pushEcommerceEvent = handleGtmEvent,
}) {
  const payload = getEcommercePayload({ event, pricing, place, quantity: booking.seats })
  if (!payload) return null

  handleGtmEvent({
    event: 'eventGA',
    eventCategory: 'checkout-payment-methods',
    eventAction: 'booking completed',
    eventLabel: JSON.stringify({
      availableMethods: paymentMethodsInformation.availableMethods,
      chosenMethod: paymentMethodsInformation.chosenMethod,
      bookingId: booking.id,
    }),
  })

  pushEcommerceEvent({
    event: 'purchase_ga4',
    ecommerce: {
      transaction_id: booking.payment.id, // Transaction ID. Required for purchases and refunds,
      coupon: pricing.campaign?.code || '',
      value: pricing.metadata.euro_pricing.total / 100, // Total transation value (incl. tax and shipping)
      tax: pricing.metadata.euro_pricing.fees / 100, // Amount of fees
      currency: 'EUR',
      items: [getProductItem(payload)],
    },
  })

  gtmPageView()

  handleGtmEvent({
    booking: {
      id: booking.payment.id,
      revenue: pricing.metadata.euro_pricing.total / 100,
      currency: 'EUR',
    },
  })

  if (settings.isAwinTrackingActive) {
    handleGtmEvent({
      awinOrderReference: booking.id,
      awinSaleAmount: formatPriceAsFloat(pricing.payer.total_paid, pricing.payer.currency),
      awinVoucherCode: pricing.campaign ? pricing.campaign.code : '',
      awinCurrency: pricing.payer.currency.iso_3,
      awinChannel: (loadCookie(COOKIE_KEYS.AFFID) || '').includes('awin-affiliation') ? 'aw' : 'other',
    })
  }

  handleGtmEvent({
    event: 'socialPurchase',
    booking: {
      id: booking.payment.id,
      revenue: pricing.metadata.euro_pricing.total / 100,
      currency: 'EUR',
    },
  })
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// Reviews tracking
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

export function gtmSortReviewsDropdown(data) {
  handleGtmEvent({
    event: 'eventGA',
    eventCategory: 'Reviews',
    eventAction: 'dropdown clicked',
    eventLabel: `sort_by:${data.sortBy}, order:${data.order}`,
  })
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// Growthbook experiments tracking
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

export function gtmExperimentTracked(experiment, result) {
  handleGtmEvent({
    event: 'experiment_viewed',
    eventCategory: 'experiment',
    eventAction: experiment.key,
    eventLabel: result.variationId,
    dimension19: `${experiment.key}:${result.variationId}`,
  })
}

// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
// Search tracking
// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

export function gtmSearchNoResults(localityOrCountry) {
  handleGtmEvent({
    event: 'search.noResults',
    eventCategory: 'search',
    eventAction: 'no results',
    eventLabel: localityOrCountry,
  })
}

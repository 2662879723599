import { getCurrentUser } from 'redux/selectors'

export const modalActions = {
  TOGGLE_LOGIN_MODAL: '@@modals/TOGGLE_LOGIN_MODAL',
  TOGGLE_REGISTER_MODAL: '@@modals/TOGGLE_REGISTER_MODAL',
  SET_REGISTER_FORM_TYPE: '@@modals/SET_REGISTER_FORM_TYPE',
}

export const toggleLoginModal =
  ({ show, force }) =>
  (dispatch, getState) => {
    if (getCurrentUser(getState()).id && show === true && !force) return
    return dispatch({ type: modalActions.TOGGLE_LOGIN_MODAL, show })
  }

export const toggleRegisterModal =
  (show, force = false) =>
  (dispatch, getState) => {
    if (!force && getCurrentUser(getState()).id && show === true) return
    if (show === false) dispatch(setRegisterModalFormType('email'))
    return dispatch({ type: modalActions.TOGGLE_REGISTER_MODAL, show })
  }

export const setRegisterModalFormType = (formType) => (dispatch) => {
  return dispatch({ type: modalActions.SET_REGISTER_FORM_TYPE, formType })
}

// handlers
const handleToggleAccountModal = (type) => (state, action) => {
  const otherType = type === 'register' ? 'login' : 'register'
  return state.setIn(['modals', type], action.show).setIn(['modals', otherType], false)
}

const handleSetFormType = (state, action) => {
  return state.setIn(['modals', 'registerType'], action.formType)
}

export const modalCustomHandlers = {
  [modalActions.TOGGLE_LOGIN_MODAL]: handleToggleAccountModal('login'),
  [modalActions.TOGGLE_REGISTER_MODAL]: handleToggleAccountModal('register'),
  [modalActions.SET_REGISTER_FORM_TYPE]: handleSetFormType,
}

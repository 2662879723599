export async function hash(input, algo = 'SHA-512') {
  try {
    const message = new TextEncoder().encode(input) // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest({ name: algo }, message) // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string
    return hashHex
  } catch (err) {
    console.error(err)
    // Not on a secure https context, so simply return a random string
    return Math.random().toString(16).substr(2, 8)
  }
}

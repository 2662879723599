import settings from 'settings'
// Libs
import React, { useMemo } from 'react'
import { mainImagesMeta } from 'helpers/ImageManager'
import { useTranslation } from 'next-i18next'
import { useTheme } from 'styled-components'
import { useRouter } from 'next/router'
import { useLocale } from 'hooks/locales/useLocale'
import { useSocialMedia } from 'hooks/socials/useSocialMedia'
// Components
import NextHead from 'next/head'
// Helpers
import { urlFactory, replaceDynamicPathByValue } from 'helpers/url'

export function ApplicationSEO() {
  const { t } = useTranslation()
  const theme = useTheme()
  const { route, query } = useRouter()
  const { locale } = useLocale()
  const { xLink, tiktokLink, linkedinLink } = useSocialMedia()

  const canonicalLinkHref = useMemo(() => {
    const getLinkHref = (path) => {
      const baseURI = urlFactory.web.canonical(locale)
      return path ? `${baseURI}${path}` : baseURI
    }
    // search page not here because we need to access to the searched place object to build the link
    switch (route) {
      case '/':
        return getLinkHref()
      case '/events/[id]':
        return getLinkHref(replaceDynamicPathByValue(route, query.id))
      case '/l/[...slug]':
      case '/private-events/[[...slug]]':
        return getLinkHref(replaceDynamicPathByValue(route, query.slug.join('/')))
      case '/landings/become-host/[[...campaign]]':
      case '/landings/gifts/[[...campaign]]':
        return getLinkHref(replaceDynamicPathByValue(route, query?.campaign?.join('/')))
      case '/landings/private-events':
      case '/landings/travel-agents':
        return getLinkHref(route)
      case '/about-us':
      case '/how-it-works':
      case '/press':
      case '/trust':
        return getLinkHref(route)
      default:
        return undefined
    }
  }, [locale, query?.campaign, query.id, query.slug, route])

  const organizationJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    brand: {
      '@type': 'Brand',
      name: 'Eatwith',
      logo: '/assets/images/favicons/favicon-196x196.png',
      image: '/assets/images/favicons/default_ogimage.jpg',
    },
    name: 'VizEat',
    alternateName: 'Eatwith',
    description:
      'Explore thousands of amazing food events in more than 130 countries. Experience a homemade meal with Eatwith hosts and discover the world like a local.',
    image: '/assets/images/favicons/default_ogimage.jpg',
    foundingDate: '2014-01-06',
    url: 'https://www.eatwith.com',
    logo: '/assets/images/favicons/favicon-196x196.png',
    sameAs: [
      'https://www.eatwith.com',
      'https://www.facebook.com/Eatwith/',
      xLink,
      'https://www.instagram.com/Eatwith/',
      linkedinLink,
      'https://www.youtube.com/channel/UCTJ97ZEd0NvGrzEJkOAQKIg',
      tiktokLink,
      'https://www.vizeat.com',
      'https://www.grubclub.com',
    ],
  }

  const websiteJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    author: {
      '@type': 'Organization',
    },
    id: 'https://www.eatwith.com',
    name: 'Eatwith',
    alternateName: 'VizEat',
    url: 'https://www.eatwith.com',
  }

  return (
    /* prettier-ignore */
    /* eslint-disable max-len */
    <NextHead>
      <title>{`${t('Meta::Title::Default::VizEat: Immersive Food Experiences')} | Eatwith`}</title>

      {canonicalLinkHref && <link rel='canonical' href={canonicalLinkHref} key='canonical' />}
        
      <meta property='og:type' content='website' />
      <meta property='og:site_name' content='Eatwith' />
      <meta key='title' property='og:title' content={t('Meta::Title::Default::VizEat: Immersive Food Experiences')} />
      <meta key='description' name='description' property='og:description' content={t('Meta::Description::Default::Book immersive food events and meal sharing experiences at one of our local hosts’ in more than 110 countries. Taste the city with locals.')} />

      {mainImagesMeta().map((props, key) => <meta key={key} {...props} />)}

      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content='@Eatwith' />

      <meta name='application-name' content='Eatwith | Taste the world with locals' />
      <meta name='theme-color' content={theme.colors.primary} />
      <meta name='msapplication-TileColor' content={theme.colors.primary} />
      {/* Avoid proposing iOS app to users on Safari */}
      {/* <meta name='apple-itunes-app' content='app-id=1076274943' /> */}
      <meta name='google-play-app' content='app-id=com.vizeat.android' />
      <meta property='fb:app_id' content='363751603760017' />

      <link rel='manifest' href='/manifest.json' />

      <link rel='apple-touch-icon-precomposed' sizes='57x57' href='/assets/images/favicons/apple-touch-icon-57x57.png' />
      <link rel='apple-touch-icon-precomposed' sizes='60x60' href='/assets/images/favicons/apple-touch-icon-60x60.png' />
      <link rel='apple-touch-icon-precomposed' sizes='72x72' href='/assets/images/favicons/apple-touch-icon-72x72.png' />
      <link rel='apple-touch-icon-precomposed' sizes='76x76' href='/assets/images/favicons/apple-touch-icon-76x76.png' />
      <link rel='apple-touch-icon-precomposed' sizes='114x114' href='/assets/images/favicons/apple-touch-icon-114x114.png' />
      <link rel='apple-touch-icon-precomposed' sizes='120x120' href='/assets/images/favicons/apple-touch-icon-120x120.png' />
      <link rel='apple-touch-icon-precomposed' sizes='144x144' href='/assets/images/favicons/apple-touch-icon-144x144.png' />
      <link rel='apple-touch-icon-precomposed' sizes='152x152' href='/assets/images/favicons/apple-touch-icon-152x152.png' />

      <link rel='icon' type='image/png' href='/assets/images/favicons/favicon-16x16.png' sizes='16x16' />
      <link rel='icon' type='image/png' href='/assets/images/favicons/favicon-32x32.png' sizes='32x32' />
      <link rel='icon' type='image/png' href='/assets/images/favicons/favicon-96x96.png' sizes='96x96' />
      <link rel='icon' type='image/png' href='/assets/images/favicons/favicon-128.png' sizes='128x128' />
      <link rel='icon' type='image/png' href='/assets/images/favicons/favicon-196x196.png' sizes='196x196' />

      <meta name='msapplication-square70x70logo' content='/assets/images/favicons/mstile-70x70.png' />
      <meta name='msapplication-TileImage' content='/assets/images/favicons/mstile-144x144.png' />
      <meta name='msapplication-square150x150logo' content='/assets/images/favicons/mstile-150x150.png' />
      <meta name='msapplication-wide310x150logo' content='/assets/images/favicons/mstile-310x150.png' />
      <meta name='msapplication-square310x310logo' content='/assets/images/favicons/mstile-310x310.png' />

      <script id='jsonld-org' key='jsonld-org' type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(organizationJSONLD) }} />
      <script id='jsonld-website' key='jsonld-website' type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(websiteJSONLD) }} />
      {settings.isInLinksActive && (<script defer src={`https://jscloud.net/lze/${settings.inLinksPID}/inlinks.js`} />)}
    </NextHead>
    /* eslint-enable max-len */
  )
}

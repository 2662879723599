// GrowthBook is an open-source platform for feature flagging and a/b testing
// https://www.growthbook.io/

import React, { useEffect } from 'react'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import settings from 'settings'
import axios from 'axios'
import PropTypes from 'helpers/proptypes'
import { useSelector } from 'react-redux'
import { getCurrentUser } from 'redux/selectors'
import { useRouter } from 'next/router'
import { gtmExperimentTracked } from 'gtm'

const growthbook = settings.isGrowthbookActive
  ? new GrowthBook({
      trackingCallback: gtmExperimentTracked,
    })
  : null

const initGrowthBook = async (attributes) => {
  if (!growthbook) return

  const response = await axios.get(settings.growthbookUrl)
  const features = response?.data?.features
  if (!features) return

  growthbook.setAttributes(attributes)
  growthbook.setFeatures(features)
}

export default function AppGrowthBookProvider({ children }) {
  const router = useRouter()
  const currentUser = useSelector(getCurrentUser)

  useEffect(() => {
    if (!growthbook) return

    // Refresh features and targeting attributes on navigation
    initGrowthBook({
      id: currentUser.id,
      browser: navigator.userAgent,
      url: router.pathname,

      loggedIn: !!currentUser.id,
      accountId: currentUser.accountId,
      isHost: currentUser.isHost,
      isNewHost: currentUser.isNewHost,
      email: currentUser.email,
    })
  }, [currentUser, router.pathname])

  if (!growthbook) return children
  return <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
}

AppGrowthBookProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

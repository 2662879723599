import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { fullyDecode } from 'helpers/url'

/**
 * Compare if two object are equal but ignores "empty fields" ('', null, undefined, [], {}) differences
 *
 * @param {object} newObject  - new object we want to compare to an existing one
 * @param {object} baseObject - base object we want to know if there is any change
 * @return {boolean}          - are the 2 object equal
 */
export function areObjectNonEmptyFieldsEqual(newObject, baseObject, { ignoreEncoding = false } = {}) {
  const allKeys = Object.keys(Object.assign({}, newObject, baseObject))
  return allKeys.reduce((areEqual, key) => {
    if (!areEqual) return false
    if (typeof newObject[key] !== typeof baseObject[key]) return false
    if (isEmpty(newObject[key]) && isEmpty(baseObject[key])) return true
    if (ignoreEncoding && typeof newObject[key] === 'string') {
      return fullyDecode(newObject[key]) === fullyDecode(baseObject[key])
    }
    return isEqual(newObject[key], baseObject[key])
  }, true)
}

export function mapObject(obj = {}, callback) {
  const entries = Object.entries(obj).map(([key, value]) => callback(key, value))
  return Object.fromEntries(entries)
}

import { schema } from 'normalizr'
import { Record } from 'immutable'

export const OrganizationSchema = new schema.Entity('organizations')

export const OrganizationRecord = Record({
  id: undefined,
  name: undefined,
  type: undefined,
  online_payment: undefined,
  display_price: undefined,
  logo_url: undefined,
})

export const emptyOrganization = new OrganizationRecord()

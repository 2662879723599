import { createSelector } from 'reselect'
import { getEntities, isFetchingRegex } from './_utils'
import { Map } from 'immutable'
import { emptyHostApplication } from 'redux/schemas'

// host applications
export const getHostApplications = getEntities('hostApplications')

export const getHostApplication = createSelector(
  getHostApplications,
  (hostApplications) => hostApplications.first() || emptyHostApplication,
)

export const getHostApplicationMetadata = createSelector(getHostApplication, (hostApplication) =>
  hostApplication.get('metadata', Map()),
)

export const fetchingHostApplication = isFetchingRegex(/\/hostApplications/)

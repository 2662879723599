import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import { UserSchema } from 'redux/schemas'

export const usersActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'users' })

export const fetchUser = (id, query) =>
  usersActions.fetch({
    url: `/users/${id}`,
    query,
    schema: { user: UserSchema },
  })

import { screenApi } from 'apis'
import { ActionsCreator, basicHandlers } from '../lib'
import { EventSchema } from 'redux/schemas'
import { validateAndFormatUserSubmittedDate } from 'helpers/time'

export const eventsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'events' })

export const fetchEvent = (id, date) => {
  date = date === 'next' ? date : validateAndFormatUserSubmittedDate(date)
  const query = date ? { date } : {}
  return eventsActions.fetch({
    url: `/events/${id}`,
    query,
    schema: { event: EventSchema },
  })
}

export const shareEvent = (id, payload) =>
  eventsActions.create({
    url: `/events/${id}/share`,
    payload,
    schema: { event: EventSchema },
  })

export const eventsCustomHandlers = {
  [eventsActions.actionTypes.DELETED]: basicHandlers.mergeEntities,
}

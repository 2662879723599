import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import { RequestSchema } from 'redux/schemas'

export const requestsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'requests' })

export const createRequest = (payload) =>
  requestsActions.create({
    url: '/requests',
    payload,
    schema: { request: RequestSchema },
  })

export const updateRequest = (requestId, payload) =>
  requestsActions.update({
    url: `/requests/${requestId}`,
    payload,
    schema: { request: RequestSchema },
  })

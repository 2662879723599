import { schema } from 'normalizr'
import { Record, Map } from 'immutable'

export const HostApplicationSchema = new schema.Entity(
  'hostApplications',
  {},
  { idAttribute: (entity) => entity._id || entity.id || 1 },
)

export const HostApplicationRecord = Record({
  id: undefined,
  metadata: Map({
    submitted_at: undefined,
  }),
})

export const emptyHostApplication = new HostApplicationRecord()

import { schema } from 'normalizr'
import { Record, List, Map } from 'immutable'
import { OverrideSchema } from './overrides'

export const ScheduleSchema = new schema.Entity('schedules', { overrides: [OverrideSchema] }, { idAttribute: 'date' })

export const ScheduleRecord = Record({
  date: undefined,

  opened_event_ids: new List(),
  opened_events: new Map(),

  closed_event_ids: new List(),
  closed_events: new Map(),

  bookable_event_ids: new List(),
  bookable_events: new Map(),

  instant_booking_event_ids: new List(),
  instant_booking_events: new Map(),

  requestable_event_ids: new List(),
  requestable_events: new Map(),

  booking_ids: new List(),
  bookings: new Map(),

  request_ids: new List(),
  requests: new Map(),

  note_id: undefined,
  note: undefined,

  overrides: new List(),
})

export const emptySchedule = new ScheduleRecord()

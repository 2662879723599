const path = require('path')

module.exports = {
  i18n: {
    defaultLocale: 'en',
    locales: ['en', 'fr', 'es', 'it', 'zh-CN', 'zh-TW', 'he'],
  },
  localePath: path.resolve('./public/locales'), // required for Vercel to find the locales files
  fallbackLng: 'en',
  nsSeparator: '~',
  keySeparator: '::',
  ns: ['common'],
  defaultNS: 'common',
  keySpaces: false,
  interpolation: {
    escape: false,
    escapeValue: false,
  },
  returnEmptyString: false,
  reloadOnPrerender: process.env.NODE_ENV === 'development',
}

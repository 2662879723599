import { schema } from 'normalizr'
import { Record } from 'immutable'

export const DietSchema = new schema.Entity('diets')
export const DietRecord = Record({
  id: undefined,
  title: '',
  description: '',
  original_title: '',
})

export const emptyDiet = new DietRecord()

import { useCallback } from 'react'
import { useTranslation } from 'next-i18next'
import i18nextConfig from '../../../next-i18next.config'
import { screenApi } from 'apis'
import { useRouter } from 'next/router'

const EXTENDED_LOCALES = {
  en: 'English',
  fr: 'Français',
  es: 'Español',
  'zh-CN': '中文（简体）',
  'zh-TW': '中文（繁體）',
  it: 'Italiano',
  he: 'עברית',
}

export function useLocale() {
  const { i18n } = useTranslation()
  const { defaultLocale } = i18nextConfig.i18n
  const { locale: routerLocale } = useRouter()

  const locale = routerLocale || defaultLocale

  const setLocale = useCallback(
    (locale) => {
      screenApi.setLanguageHeader(locale)
      i18n.changeLanguage(locale)
    },
    [i18n],
  )

  return {
    setLocale,
    locale,
    getExtendedLocale: (locale) => EXTENDED_LOCALES[locale] || EXTENDED_LOCALES[defaultLocale],
  }
}

// helpers
import { fromJS } from 'immutable'
import { HYDRATE } from 'next-redux-wrapper'
// redux
import { recordsByEntities } from 'redux/schemas'

export const settingsActions = {
  HYDRATE,
}

function toImmutableState(state) {
  if (!state || state.toJS) return state
  // deeply immutable state
  const immutableState = fromJS(state)
  const reducerState = immutableState.set('loading', immutableState.get('loading').toSet())
  const entities = reducerState.get('entities').map((entity, entityName) => {
    return entity.map((value) => new recordsByEntities[entityName](value))
  })
  return reducerState.set('entities', entities)
}

// On page transition, Next.js sends a new store calculated from get(ServerSide|Static)Props
// The goal is to merge the data calculated on the server with the state existing in the client
const handleMergeStore = (state, action) => {
  if (!action.payload) return state
  return state.mergeDeep(toImmutableState(action.payload))
}

export const appSettingsCustomHandlers = {
  [settingsActions.HYDRATE]: handleMergeStore,
}

import init from '@vizeat/redux-entities'
import { normalize } from 'normalizr'
import { Map } from 'immutable'
import { recordsByEntities } from 'redux/schemas'

const mergeMetadata =
  (entityName) =>
  (
    state,
    {
      payload: {
        params, // to return all additional results in order
        data: { result },
      },
    },
  ) => {
    if (result.total === undefined || result.total === null) return state

    return state.mergeIn(['metadata', entityName], {
      count: result.total,
      order: result[entityName],
      offset: result.offset,
      size: result.size,
    })
  }

const clearHandler = (reducer) => (state) => state.setIn(['entities', reducer], new Map())

const mergeDeepNestedList = {
  users: {
    events: true,
  },
  search: {
    events: true,
  },
}

const overrideWithDefaults = {
  schedules: true,
  overrides: true,
}

const { getInitialState, basicHandlers, ActionsCreator, buildHandlers, chainReducers } = init({
  recordsByEntities,
  normalize,
  options: { mergeDeepNestedList, overrideWithDefaults },
})

export { getInitialState, basicHandlers, ActionsCreator, buildHandlers, chainReducers, mergeMetadata, clearHandler }

export default {
  getInitialState,
  basicHandlers,
  ActionsCreator,
  buildHandlers,
  chainReducers,
}

import settings from 'settings'
import { isVideo } from 'helpers/files'

export function scaleCropFile({
  fileId,
  format,
  quality,
  blur,
  orFile = '/assets/images/blank.png',
  placement = 'center',
  size = '710x420',
  smart = false,
} = {}) {
  if (!fileId && orFile) return orFile // default greyscale image
  const resizeMode = smart ? `smart/${placement}/` : `${placement}/-/progressive/yes/`
  let url = `${settings.fileUrl}files/${fileId}/-/scale_crop/${size}/${resizeMode}`
  if (quality) url = url + `-/quality/${quality}/`
  if (format) url = url + `-/format/${format}/`
  if (blur) url = url + `-/blur/${blur}/`
  return url
}

export function previewFile(fileId = 436403, size = '710x500') {
  return `${settings.fileUrl}files/${fileId}/-/preview/${size}/-/progressive/yes/`
}

export function urlImageMeta(url, size) {
  if (!url) return
  const captureSize = /\/([0-9]+?x[0-9]+?)\//
  if (!size && captureSize.test(url)) {
    const match = url.match(captureSize)
    size = { width: match.split('x')[0], height: match.split('x')[1] }
  }
  const ext = url.slice(url.lastIndexOf('.') + 1) || 'jpg'
  return [
    { property: 'og:image', content: url },
    { property: 'og:image:width', content: size.width },
    { property: 'og:image:height', content: size.height },
    { property: 'og:image:type', content: `image/${ext}` },
  ]
}

export function mainImagesMeta(file, size = '1200x1200') {
  if (!file || !file.id) {
    return [
      { property: 'og:image', content: '/assets/images/favicons/default_ogimage.jpg' },
      { property: 'og:image:type', content: 'image/jpg' },
      { property: 'og:image:width', content: '2400' },
      { property: 'og:image:height', content: '1200' },
    ]
  }

  const ext = (file.path && file.path.slice(file.path.lastIndexOf('.') + 1)) || 'jpg'
  return [
    { property: 'og:image', content: previewFile(file.id, size) },
    { property: 'og:image:width', content: size.split('x')[0] },
    { property: 'og:image:height', content: size.split('x')[1] },
    { property: 'og:image:type', content: `image/${ext}` },
  ]
}

// image from prismic can either be
// - a full URL (starting with http)
// - an ID which points to the kitchen
export const getPrismicImage = (image, size) => {
  if (typeof image === 'string' && image.startsWith('http')) return image
  return scaleCropFile({ fileId: image, size })
}

// Map representing [videoFileId] : coverImageFileId
const customVideoCovers = {
  427978: 436611,
  427979: 436606,
  427980: 436613,
  427981: 436610,
  427982: 436609,
  427983: 436607,
  427984: 436608,
  427985: 436612,
}

export const getCarouselImage = (file, crop = false, size = '1024x632') => {
  const isVideoFile = isVideo(file.path)
  const videoCustomCoverFileId = customVideoCovers[file.id]
  // Early return for videos without a custom cover
  if (isVideoFile && !videoCustomCoverFileId) {
    return 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7='
  }
  const fileId = isVideoFile ? videoCustomCoverFileId : file.id
  return crop ? scaleCropFile({ fileId, size }) : previewFile(fileId, size)
}

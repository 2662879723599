import { schema } from 'normalizr'
import { Record, List } from 'immutable'

export const TripAdvisorUserSchema = new schema.Entity(
  'tripAdvisorUsers',
  {},
  {
    idAttribute: 'location_id',
  },
)

export const TripAdvisorUserRecord = Record({
  awards: new List(),
  description: '',
  id: undefined,
  location_string: '',
  name: '',
  num_reviews: '',
  ranking_data: new Map(),
  rating_image_url: '',
  rating: '',
  reviews: new List(),
  web_url: '',
  write_review: '', // URL supplied by TripAdvisor where users can submit a review
})

export const emptyTripAdvisorUser = new TripAdvisorUserRecord()

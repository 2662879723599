import { useCallback } from 'react'
import { useRouter } from 'next/router'
import staticLanguages from 'static/languages.json'
import { useLocale } from 'hooks/locales/useLocale'
import { COOKIE_KEYS, saveCookie } from 'helpers/cookies'

export function useLanguage() {
  const router = useRouter()
  const { locale: appLocale, setLocale } = useLocale()

  const setAppLanguage = useCallback(
    async (locale) => {
      saveCookie({
        name: COOKIE_KEYS.EW_LANGUAGE,
        value: locale,
        duration: 3600 * 24 * 365,
      })

      setLocale(locale)
      return router.replace(router.asPath, undefined, { locale })
    },
    [router.replace], // eslint-disable-line react-hooks/exhaustive-deps
    // 👆 Router methods must not be addedd here because they are not memoized and hence cause infinite rerenders if added to deps array https://github.com/vercel/next.js/issues/18127
  )

  function getLocaleFromLanguageId(id) {
    const language = staticLanguages.data.find((l) => l.id === id)
    return language?.locale
  }

  return {
    appLanguage: staticLanguages.data.find((l) => l.locale === appLocale),
    getLocaleFromLanguageId,
    languages: staticLanguages.data,
    setAppLanguage,
  }
}

import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import reducer from 'redux/reducer'
import * as SentryReact from '@sentry/react'
import { createWrapper } from 'next-redux-wrapper'

// Inspired by Redux-Thunk: https://github.com/gaearon/redux-thunk
function thunkCatchPromise(activePromises = []) {
  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      if (typeof action === 'function') {
        const result = action(dispatch, getState)

        const isPromise = typeof result === 'object' && typeof result.then === 'function'
        if (isPromise) {
          const activePromise = result.catch((e) => {
            // Ignore errors because they're handled in actions
          })
          // Add Promise to activeActions
          activePromises.push(activePromise)
          // Remove Promise from activeActions when finished
          activePromise.then(() => activePromises.splice(activePromises.indexOf(activePromise), 1))
        }
        return result
      }

      return next(action)
    }
}

const sentryReduxEnhancer = SentryReact.createReduxEnhancer({
  // Optionally pass options
})

export const initializeStore = (preloadedState) => {
  const middlewares = [thunkCatchPromise()]
  return createStore(reducer, preloadedState, composeWithDevTools(applyMiddleware(...middlewares), sentryReduxEnhancer))
}

// The argument received by the wrapper initialize is next context and not the initialState
// so we need to call initializeStore without argument
const initializeWrapperStore = () => initializeStore()

export const wrapper = createWrapper(initializeWrapperStore, {
  debug: false,
  serializeState: (state) => JSON.stringify(state),
  deserializeState: (state) => JSON.parse(state),
})

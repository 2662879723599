import { schema } from 'normalizr'
import { Record } from 'immutable'

export const LanguageSchema = new schema.Entity('languages')
export const LanguageRecord = Record({
  id: undefined,
  title: '',
  original_title: '',
  translated: false,
  activated: false,
  i_speak: '',
  alpha2: '',
  locale: undefined,
  'alpha3-b': null,
  'alpha3-t': null,
})

export const emptyLanguage = new LanguageRecord()

import { schema } from 'normalizr'
import { Record } from 'immutable'
import { OrganizationSchema } from './organizations'

export const PartnerSchema = new schema.Entity('partners', {
  organization: OrganizationSchema,
})

export const PartnerRecord = Record({
  id: undefined,
  account_id: undefined,
  name: undefined,
  description: undefined,
  organization: undefined,
})

export const emptyPartner = new PartnerRecord()

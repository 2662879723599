import { useIPGeolocationQuery } from 'hooks/queries/IPGeolocation/useIPGeolocationQuery'
import * as icons from '@vizeat/components/es6/assets/icons'

const INSTAGRAM_LINKS = {
  es: 'https://www.instagram.com/eatwith_es/',
  gb: 'https://www.instagram.com/eatwithlondon/',
  il: 'https://www.instagram.com/eatwith_il/',
  default: 'https://www.instagram.com/eatwith/',
}

const FACEBOOK_LINKS = {
  es: 'https://www.facebook.com/EatwithES/',
  gb: 'https://www.facebook.com/eatwithlondon/',
  il: 'https://www.facebook.com/Eatwithil/',
  default: 'https://www.facebook.com/Eatwith/',
}

const SOCIAL_MEDIA_ICONS = {
  facebook: 'IconFacebook',
  instagram: 'IconInstagram',
  x: 'IconX',
  wechat: 'IconWechatUnfilled',
  linkedin: 'IconLinkedin',
  tiktok: 'IconTiktok',
}

export function useSocialMedia() {
  const { data } = useIPGeolocationQuery()
  const lowerCaseCountry = data?.country?.toLowerCase()

  function getLinkByCountry(links) {
    const entries = Object.entries(links).find(([key]) => key === lowerCaseCountry)
    if (!entries) return links.default
    return entries[1]
  }

  const instagramLink = getLinkByCountry(INSTAGRAM_LINKS)
  const facebookLink = getLinkByCountry(FACEBOOK_LINKS)
  const xLink = 'https://twitter.com/Eatwith'
  const wechatLink = 'https://eatwith.net.cn'
  const linkedinLink = 'https://www.linkedin.com/company/eatwith/'
  const tiktokLink = 'https://www.tiktok.com/@eatwithlocals'

  const iconLinks = [
    { to: facebookLink, Icon: icons[SOCIAL_MEDIA_ICONS.facebook] },
    { to: xLink, Icon: icons[SOCIAL_MEDIA_ICONS.x] },
    { to: instagramLink, Icon: icons[SOCIAL_MEDIA_ICONS.instagram] },
    { to: wechatLink, Icon: icons[SOCIAL_MEDIA_ICONS.wechat] },
    { to: linkedinLink, Icon: icons[SOCIAL_MEDIA_ICONS.linkedin] },
    { to: tiktokLink, Icon: icons[SOCIAL_MEDIA_ICONS.tiktok] },
  ]

  return {
    instagramLink,
    facebookLink,
    xLink,
    wechatLink,
    linkedinLink,
    tiktokLink,
    isSocialAuthEnabled: data?.country !== 'CN',
    iconLinks,
  }
}

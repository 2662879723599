import { isObject } from 'lodash'
import { mapObject } from './object'

export const STALE_TIME = 1000 * 60 * 5 // 5 minutes

export function serializeQueryKey(prefix, payload, currency) {
  if (!payload) return [prefix]

  // recursively convert all values to strings to avoid invalidating
  // the cache due to a type change (router vs local state)
  function convertObjectValuesToString(obj) {
    return mapObject(obj, (key, value) => {
      return [key, toString(value)]
    })
  }

  function toString(value) {
    if (typeof value === 'string' || typeof value === 'undefined') return value // undefined values are removed by react-query
    if (Array.isArray(value)) return value.map(toString)
    if (isObject(value)) return convertObjectValuesToString(value)
    return String(value)
  }

  const queryKey = [prefix, convertObjectValuesToString(payload)]
  return currency ? [...queryKey, currency] : queryKey
}

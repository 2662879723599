import { schema } from 'normalizr'
import { Record, Map } from 'immutable'
import { formatPlaceRecord } from 'helpers/places'

export const PlaceSchema = new schema.Entity('places', {
  idAttribute: (entity) => entity.id || entity.provider_id,
  processStrategy: (entity) => (entity.scope === 'GOOGLE' ? formatPlaceRecord(entity) : entity),
})

export const PlaceRecord = Record({
  id: undefined,
  user_id: undefined,
  administrative_area_level_1: '',
  administrative_area_level_2: '',
  additional_info: '',
  address: '',
  coordinates: new Map({
    latitude: undefined,
    longitude: undefined,
    zoom: undefined,
    radius: undefined,
    geometry: undefined,
  }),
  country: '',
  country_iso: '',
  cover_id: undefined,
  created_at: undefined,
  formatted: '',
  provider: '',
  provider_id: '',
  lat: undefined,
  lng: undefined,
  locality: '',
  postal_code: '',
  route: '',
  street_number: '',
  timezone: '',
  title: '',
  updated_at: undefined,
})

export const emptyPlace = new PlaceRecord()

import { schema } from 'normalizr'
import { Record } from 'immutable'

export const PaymentMethodSchema = new schema.Entity('paymentMethods')

export const PaymentMethodRecord = Record({
  id: undefined,
  object: undefined,
  billing_details: undefined,
  card: undefined,
  created: undefined,
  customer: undefined,
  livemode: undefined,
  metadata: undefined,
  type: undefined,
})

export const emptyPaymentMethod = new PaymentMethodRecord()

import { createSelector } from 'reselect'
import { getEntities, isFetching, isUpdating, getObjId, getUpdateError } from './_utils'
import { getOrganization } from './organizations'
import { getPartner } from './partners'
import { getLanguage } from './languages' // need not to refer redux/selectors/index, most likely due to circular dependencies
import { getHostApplicationMetadata } from './hostApplications' // need not to refer redux/selectors/index, most likely due to circular dependencies
import { emptyCurrentUser } from 'redux/schemas'
import { screenApi } from 'apis'

export const getCurrentUsers = getEntities('currentUsers')

export const getCurrentUser = createSelector(
  getCurrentUsers,
  (currentUsers) => currentUsers.first() || emptyCurrentUser,
)

// TODO: fix this because it's impossible to have selectors react to things outside of state (screen api headers)
// For more details look https://github.com/vizeat/webapp/issues/3644
// Un-memoized selector because it depends on variable outside the redux store
export const getIsCurrentUserLoggedIn = (state) => !!getCurrentUser(state).id && !!screenApi.getHeader('Authorization')

export const getCurrentUserPartner = createSelector(
  getCurrentUser,
  (state) => state,
  (currentUser, state) => getPartner(state, getObjId(currentUser, 'partner')),
)

export const getCurrentUserOrganization = createSelector(
  getCurrentUserPartner,
  (state) => state,
  (currentUser, state) => getOrganization(state, getObjId(currentUser, 'organization')),
)

export const getCurrentUserCountryISO = createSelector(getCurrentUser, (currentUser) => currentUser.country_iso)

export const getCurrentUserFullName = createSelector(
  getCurrentUser,
  (currentUser) => `${currentUser.firstname} ${currentUser.lastname}`,
)

export const getCurrentUserEmail = createSelector(getCurrentUser, (currentUser) => currentUser.email)

export const getCurrentUserPhone = createSelector(getCurrentUser, (currentUser) => currentUser.phone)

export const getCurrentUserLanguage = createSelector(
  getCurrentUser,
  (state) => state,
  (currentUser, state) => getLanguage(state, currentUser.language),
)

export const getCurrentUserNewsletter = createSelector(getCurrentUser, (currentUser) => currentUser.newsletter)

export const getCurrentUserProperties = createSelector(getCurrentUser, (currentUser, state) => currentUser.properties)

export const getCurrentUserIsHostApplicant = createSelector(
  getCurrentUser,
  getHostApplicationMetadata,
  (user, metadata) => user.host_application_id && metadata.get('status') === 'approved' && !user.isActiveHost,
)

export const getCurrentUserIsNewHost = createSelector(
  getCurrentUser,
  (user) => user.host_application_id && user.isNewHost,
)

export const fetchingCurrentUsers = isFetching('/users/profile')
export const updatingCurrentUsers = isUpdating('/users/profile')

// errors
export const getUpdateCurrentUserError = getUpdateError('/users/profile')

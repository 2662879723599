import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import { CurrentUserSchema } from 'redux/schemas'

export const currentUsersActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'currentUsers' })

export const fetchCurrentUsers = () =>
  currentUsersActions.fetch({
    url: '/users/profile',
    schema: { user: CurrentUserSchema },
  })

export const updateCurrentUser = (payload) =>
  currentUsersActions.update({
    url: '/users/profile',
    payload,
    schema: { user: CurrentUserSchema },
  })

export const updateHygieneGuidelines = (payload) =>
  currentUsersActions.update({
    url: '/users/profile/guidelines/hygiene',
    payload,
    schema: { user: CurrentUserSchema },
  })

import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import MomentPropTypes from 'react-moment-proptypes'

export const i18nextPropTypes = {
  t: PropTypes.func.isRequired,
  i18nLoadedAt: PropTypes.any,
  i18n: PropTypes.any,
}

export const reduxPropTypes = {
  dispatch: PropTypes.func,
}

export const routerPropTypes = {
  router: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
    pathname: PropTypes.string,
    query: PropTypes.shape({}),
    asPath: PropTypes.string,
  }),
}

export const prismicPropTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      slice_type: PropTypes.string,
      slice_label: PropTypes.string,
      items: PropTypes.arrayOf(PropTypes.shape({})),
      primary: PropTypes.shape({}),
    }),
  ),
}

PropTypes.immutable = ImmutablePropTypes
PropTypes.moment = MomentPropTypes.momentObj

export default PropTypes

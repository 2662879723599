import { useEffect, useMemo, useState } from 'react'

export function useFullPageCarousel(initialFiles) {
  const [{ pageYOffset, galleryFiles, isFullPageCarouselVisible, selectedCarouselImageIndex }, setState] = useState({
    pageYOffset: 0,
    galleryFiles: initialFiles,
    isFullPageCarouselVisible: false,
    selectedCarouselImageIndex: undefined,
  })

  useEffect(() => {
    if (pageYOffset && !isFullPageCarouselVisible) window.scrollTo({ top: pageYOffset, behavior: 'smooth' })
  }, [isFullPageCarouselVisible, pageYOffset])

  useEffect(() => {
    const intercomContainer = document.getElementById('intercom-container')
    if (intercomContainer) intercomContainer.style.display = isFullPageCarouselVisible ? 'none' : 'block'
  }, [isFullPageCarouselVisible])

  return useMemo(
    () => ({
      galleryFiles,
      selectedCarouselImageIndex,
      handleFullPageCarouselOpen: (index, files = initialFiles) => {
        setState({
          pageYOffset: window.pageYOffset,
          galleryFiles: files,
          isFullPageCarouselVisible: true,
          selectedCarouselImageIndex: index,
        })
      },
      handleFullPageCarouselClose: () => {
        setState((prev) => ({
          ...prev,
          isFullPageCarouselVisible: false,
          selectedCarouselImageIndex: undefined,
        }))
      },
      isFullPageCarouselVisible,
    }),
    [galleryFiles, initialFiles, isFullPageCarouselVisible, selectedCarouselImageIndex],
  )
}

import { useLocale } from 'hooks/locales/useLocale'

export function useIsRtl() {
  const { locale } = useLocale()
  const isRTL = ['he'].includes(locale)

  return {
    isRTL,
    direction: isRTL ? 'rtl' : 'ltr',
  }
}

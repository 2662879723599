import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import { HostApplicationSchema } from 'redux/schemas'

export const hostApplicationsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'hostApplications' })

export const fetchHostApplication = (id, payload) =>
  hostApplicationsActions.fetch({
    url: `/hostApplications/${id}`,
    payload,
    schema: { hostApplication: HostApplicationSchema },
  })

import { schema } from 'normalizr'
import { Record } from 'immutable'
import { FileSchema } from './files'

export const DestinationSchema = new schema.Entity('destinations', {
  cover: FileSchema,
})

export const DestinationRecord = Record({
  id: undefined,
  title: '',
  cover_id: undefined,
  cover: undefined,
})

export const emptyDestination = new DestinationRecord()

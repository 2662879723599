import { useEffect, useState } from 'react'
import { usePrevious } from './usePrevious'

export function useHasLoadingSucceeded(isLoading = false, error = undefined) {
  const previousIsLoading = usePrevious(isLoading)
  const [hasLoadingSucceeded, setHasLoadingSucceeded] = useState(false)

  useEffect(() => {
    setHasLoadingSucceeded(previousIsLoading && !isLoading && !error)
  }, [isLoading, error, previousIsLoading])

  return hasLoadingSucceeded
}

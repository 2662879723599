import moment from 'moment'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

export function useMomentEventDateFromRouterQuery() {
  const date = useRouter().query.date

  return useMemo(
    () => (date && moment.utc(date, 'YYYY-MM-DD').isValid() ? moment.utc(date, 'YYYY-MM-DD') : undefined),
    [date],
  )
}

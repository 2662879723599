import { schema } from 'normalizr'
import { Record } from 'immutable'
import { LanguageSchema, LanguageRecord } from './languages'

export const AccountSchema = new schema.Entity('accounts', {
  language: LanguageSchema,
})

export const AccountRecord = Record({
  id: undefined,
  email: '',
  facebook_id: undefined,
  facebook_token: undefined,
  google_id: undefined,
  language: new LanguageRecord(),
  language_id: undefined,
  oauth_token: undefined,
  stripe_account_id: undefined,
  stripe_customer_id: undefined,
  validation_token: undefined,
  validated_at: undefined,
  created_at: undefined,
  updated_at: undefined,
  deactivated_at: undefined,
})

export const emptyAccount = new AccountRecord()

import { screenApi } from 'apis'
import { ActionsCreator, mergeMetadata } from '../lib'
import { PlaceSchema } from 'redux/schemas'

export const placesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'places' })

export const fetchPlace = (id) =>
  placesActions.fetch({
    url: `places/${id}`,
    schema: { place: PlaceSchema },
  })

export const placesCustomHandlers = {
  [placesActions.actionTypes.FETCHED]: mergeMetadata('places'),
}

import * as yup from 'yup'
import { cast } from 'helpers/schema'

const IPGeolocationSchema = yup.object({
  city: yup.string().nullable().default(null),
  country: yup.string().nullable().default(null),
  latitude: yup.string().nullable().default(null),
  longitude: yup.string().nullable().default(null),
})

function create(data) {
  return cast(IPGeolocationSchema, data)
}

export const emptyIPGeolocation = create()

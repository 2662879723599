import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import { Loader } from '@vizeat/components/es6/components/Loader'
import { useIsRtl } from 'hooks/locales/useIsRtl'

export function AppLoader() {
  const router = useRouter()
  const prevPathnameRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const { isRTL } = useIsRtl()

  useEffect(() => {
    function handleStart(url) {
      const pathname = url.split('?').shift() // ignore query strings
      if (prevPathnameRef.current === pathname) return
      prevPathnameRef.current = pathname
      setIsLoading(true)
    }

    function handleComplete() {
      setIsLoading(false)
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeError', handleComplete)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleComplete)
      router.events.off('routeChangeError', handleComplete)
    }
  })

  return <Loader isLoading={isLoading} isRTL={isRTL} />
}

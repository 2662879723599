import { schema } from 'normalizr'
import { Record, List, Set, Map } from 'immutable'

import { AccountSchema } from './accounts'
import { FileSchema } from './files'
import { PlaceSchema } from './places'
import { CurrencySchema } from './currencies'
import { LanguageSchema } from './languages'
import { PartnerSchema } from './partners'

export const CurrentUserSchema = new schema.Entity('currentUsers', {
  account: AccountSchema,
  avatar: FileSchema,
  billing_place: PlaceSchema,
  currency: CurrencySchema,
  partner: PartnerSchema,
  files: [FileSchema],
  language: LanguageSchema,
  languages: [LanguageSchema],
})

export const CurrentUserRecord = Record({
  id: undefined,

  account: undefined,
  account_id: undefined,
  age: '',
  avatar: undefined,
  billing_place: undefined,
  birth_date: '',
  civility: '',
  country: undefined,
  country_iso: undefined,
  currency: undefined,
  currency_id: undefined,
  description: '',
  email: '',
  events: new List(),
  files: new Set(),
  firstname: '',
  host_application_id: undefined,
  language: undefined,
  languages: new List(),
  lastname: '',
  newsletter: false,
  partner: undefined,
  phone: undefined,
  payout_gateway: undefined,
  paypal_id: undefined,
  place: undefined,
  place_id: undefined,
  properties: new Map(),
  rating: new Map(),
  isHost: false,
  isActiveHost: false,
  isNewHost: false,
  tos: false,
  hasValidatedHygieneGuidelines: undefined,

  created_at: undefined,
  updated_at: undefined,
})

export const emptyCurrentUser = new CurrentUserRecord()

// libs
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { useLocale } from 'hooks/locales/useLocale'
import { useCurrency } from 'hooks/currencies/useCurrency'
import { useLanguage } from 'hooks/languages/useLanguage'
import { usePrevious } from 'hooks'
import omit from 'lodash/omit'
import moment from 'moment'
// actions
import { authByCookie } from 'redux/actions'
import { getCurrentUser } from 'redux/selectors'
import { gtmLanguageCurrency } from 'gtm'
import { useIsRtl } from 'hooks/locales/useIsRtl'
import { useMediaQuery } from '@vizeat/components/es6/components/MediaQuery'
import { loadIntercom, manageIntercomDisplay } from 'services/intercom'
import { COOKIE_KEYS, deleteCookie, saveCookie } from 'helpers/cookies'

function useIntercomDisplay() {
  const { locale } = useLocale()
  const { direction } = useIsRtl()
  const matchDesktop = useMediaQuery('desktop')

  // Initially load intercom and update on locale change
  useEffect(() => {
    loadIntercom(locale)
    document.documentElement.dir = direction
  }, [locale, direction])

  // Update intercom visibility on width change
  useEffect(() => {
    manageIntercomDisplay({ device: matchDesktop ? 'desktop' : 'mobile' })
  }, [matchDesktop])
}

function useAppTrackingSource() {
  const { query } = useRouter()
  const { affid, ta_click_id: taClickId, brand } = query

  useEffect(() => {
    // Must be a unix timestamp in seconds (we need to remove the milliseconds)
    const unixTime = Math.floor(Date.now() / 1000)

    // If has new affid, then update cookie and remove old taClickId if no new is present in URL
    if (affid) {
      saveCookie({ name: COOKIE_KEYS.AFFID, value: affid })
      saveCookie({ name: COOKIE_KEYS.AFFID_CLICKED_AT, value: unixTime })
      if (!taClickId) {
        deleteCookie(COOKIE_KEYS.TA_CLICK_ID)
        deleteCookie(COOKIE_KEYS.TA_CLICK_ID_CLICKED_AT)
      }
    }
    // If has new taClickId, then update cookie and remove old affid if no new is present in URL
    if (taClickId) {
      saveCookie({ name: COOKIE_KEYS.TA_CLICK_ID, value: taClickId })
      saveCookie({ name: COOKIE_KEYS.TA_CLICK_ID_CLICKED_AT, value: unixTime })
      if (!affid) {
        deleteCookie(COOKIE_KEYS.AFFID)
        deleteCookie(COOKIE_KEYS.AFFID_CLICKED_AT)
      }
    }
  }, [affid, taClickId])

  useEffect(() => {
    if (brand) saveCookie({ name: COOKIE_KEYS.BRAND, value: brand, duration: 3600 * 24 })
  }, [brand])
}

function useMomentJs() {
  const { locale } = useLocale()

  useEffect(() => {
    const { language, userLanguage, browserLanguage, systemLanguage } = window.navigator
    const lowerLocale = locale.toLowerCase()

    async function loadEnglishLocale() {
      const lowerLanguage = (language || userLanguage || browserLanguage || systemLanguage || 'en-us').toLowerCase()
      const englishLocale = lowerLanguage === 'en-us' ? 'en-us' : 'en-gb'
      if (englishLocale !== 'en-us') await import(`moment/locale/${englishLocale}`)
      moment.locale(englishLocale)
    }

    async function loadGenericLocale() {
      await import(`moment/locale/${lowerLocale}`)
      moment.locale(lowerLocale)
    }
    locale === 'en' ? loadEnglishLocale() : loadGenericLocale()
  }, [locale])
}

function useLocaleAndCurrency() {
  const router = useRouter()
  const { locale } = useLocale()
  const { appCurrency, appCurrencyIso3, getCurrencyIso3ById, setAppCurrency } = useCurrency()
  const { getLocaleFromLanguageId, setAppLanguage } = useLanguage()

  const currentUser = useSelector(getCurrentUser)
  const previousCurrentUserId = usePrevious(currentUser?.id)
  const userHasLoggedIn = currentUser?.id !== previousCurrentUserId
  const currentUserLocale = getLocaleFromLanguageId(currentUser.language)
  const currentUserCurrency = currentUser?.currency_id || currentUser?.currency

  useEffect(() => {
    gtmLanguageCurrency(locale, appCurrencyIso3)
  }, [appCurrencyIso3, locale])

  useEffect(() => {
    if (currentUserLocale && currentUserLocale !== locale) {
      setAppLanguage(currentUserLocale)
    }
  }, [currentUserLocale, locale, setAppLanguage])

  useEffect(() => {
    if (userHasLoggedIn && currentUserCurrency && currentUserCurrency !== appCurrency.id) {
      setAppCurrency(getCurrencyIso3ById(currentUserCurrency))
    }
  }, [appCurrency.id, currentUserCurrency, getCurrencyIso3ById, setAppCurrency, userHasLoggedIn])

  // Remove initial currency set in the URL to prevent it causing trouble as user logs in, changes currency in the app, other.
  useEffect(() => {
    if (router.query.c || router.query.fco) {
      router.replace({ pathname: router.pathname, query: omit(router.query, ['c', 'fco']) }, undefined, {
        shallow: true,
      })
    }
  }, [router.query.c, router.query.fco]) // eslint-disable-line react-hooks/exhaustive-deps
  // 👆 Router methods must not be addedd here because they are not memoized and hence cause infinite rerenders if added to deps array https://github.com/vercel/next.js/issues/18127
}

export function AppLayout(props) {
  const dispatch = useDispatch()

  useMomentJs()
  useIntercomDisplay()
  useAppTrackingSource()
  useLocaleAndCurrency()

  useEffect(() => {
    dispatch(authByCookie())
  }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps
  // 👆 Disabled because this should be run only on the first mount

  return props.children
}

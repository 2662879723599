import React, { createContext, useContext, useState, useCallback } from 'react'
import PropTypes from 'helpers/proptypes'
import { konstants } from '@vizeat/helpers'
import { COOKIE_KEYS, saveCookie, loadCookie } from 'helpers/cookies'
import { screenApi } from 'apis'

const AppContext = createContext({
  appCurrencyIso3: undefined,
  setAppCurrencyIso3: () => undefined,
})

export function CurrencyProvider({ children }) {
  const [currencyIso3, setCurrencyIso3] = useState(
    loadCookie(COOKIE_KEYS.EW_CURRENCY) || konstants.DEFAULT_CURRENCY.iso_3,
  )

  const setAppCurrencyIso3 = useCallback((iso3) => {
    saveCookie({
      name: COOKIE_KEYS.EW_CURRENCY,
      value: iso3,
      duration: 3600 * 24 * 365,
    })
    screenApi.setHeader('X-Currency', iso3)
    setCurrencyIso3(iso3)
  }, [])

  return (
    <AppContext.Provider value={{ appCurrencyIso3: currencyIso3, setAppCurrencyIso3 }}>{children}</AppContext.Provider>
  )
}

CurrencyProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function useCurrencyContext() {
  return useContext(AppContext)
}

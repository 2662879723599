import settings from 'settings'
import moment from 'moment'
import Cookies from 'universal-cookie'
import { konstants as constants } from '@vizeat/helpers'

const cookies = new Cookies()

function getCookieOptions(duration) {
  return {
    domain: settings.cookieDomainName,
    path: '/',
    maxAge: duration,
    expires: moment.utc().add(duration, 'seconds').toDate(),
  }
}

export const COOKIE_KEYS = constants.COOKIE_KEYS

export function saveCookie({ name, value, duration = 3600 * 24 * 30 }) {
  cookies.set(name, value, getCookieOptions(duration))
}

export function loadCookie(name, rawCookies) {
  const cookiesStore = rawCookies ? new Cookies(rawCookies) : cookies
  return cookiesStore.get(name) || null
}

export function deleteCookie(name) {
  // Required to pass options, otherwise cookies won't be removed
  cookies.remove(name, getCookieOptions(0))
}

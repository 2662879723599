import { createSelector } from 'reselect'
import moment from 'moment'
import { getEntities, getEntity, isFetching, isCreating, getCreateError } from './_utils'
import { getCurrentUser } from 'redux/selectors'
import { emptyRequest } from 'redux/schemas'

export const getRequests = getEntities('requests')
export const getRequest = getEntity('requests')

export const getRequestsForCurrentUser = createSelector(getRequests, getCurrentUser, (requests, currentUser) =>
  requests.filter((request) => request.user_id === currentUser.id),
)

export const getRequestForEventAndDate = createSelector(
  getRequestsForCurrentUser,
  (_state, eventId, date) => ({ eventId, date }),
  (requests, { eventId, date }) =>
    requests
      .sort((a, b) => moment.utc(b.created_at).diff(moment.utc(a.created_at))) // newest first
      .find(
        (request) =>
          request.event_id === eventId &&
          (date ? request.date === date : !request.date) &&
          !request.canceled_at &&
          !request.expired_at,
        null,
        emptyRequest, // default value
      ),
)

// notifiers
export const fetchingRequests = isFetching('/requests')
export const creatingRequest = isCreating('/requests')
export const fetchingRequest = (state, id) => isFetching(`/requests/${id}`)(state)

// errors
export const getCreateRequestError = getCreateError('/requests')

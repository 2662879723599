import { isCreating, getCreateError } from './_utils'

// register
export const registeringUser = isCreating('/register')
export const getRegisterError = getCreateError('/register')

// login
export const loggingIn = isCreating('/login') || isCreating('/login/google') || isCreating('/login/facebook')
export const getLoginError =
  getCreateError('/login') || getCreateError('/login/google') || getCreateError('/login/facebook')

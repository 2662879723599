import { createSelector } from 'reselect'
import { getEntities, getEntity } from './_utils'
import { getAmenity } from 'redux/selectors'
import { emptyPlace } from 'redux/schemas/places'

export const getPlaces = getEntities('places')
export const getPlace = getEntity('places')

export const getPlaceAmenities = createSelector(
  getPlace,
  (state) => state,
  (place, state) => place.amenities.map((id) => getAmenity(state, id)).toList(),
)

export const getPlaceByFormatted = createSelector(
  getPlaces,
  (state, formatted) => ({ state, formatted }),
  (places, { formatted }) => {
    return places.find((place) => place.formatted === formatted, null, emptyPlace)
  },
)

import { createSelector } from 'reselect'
import { List } from 'immutable'
import { getEntities, getEntity, getFetchError, getObjId, isFetching } from './_utils'

import {
  getAlcohol,
  getDiet,
  getFile,
  getFood,
  getLanguage,
  getOverride,
  getPlace,
  getUser,
  getUserAvatar,
} from 'redux/selectors'

import { emptyOverride } from 'redux/schemas'

import { getFiles } from './files' // need not to refer redux/selectors/index, most likely due to circular dependencies

export const getEvents = getEntities('events')
export const getEvent = getEntity('events')

export const getEventHost = createSelector(
  getEvent,
  (state) => state,
  (event, state) => getUser(state, getObjId(event, 'user')),
)

export const getEventHostLanguages = createSelector(
  getEventHost,
  (state) => state,
  (host, state) => host.languages.map((id) => getLanguage(state, id)),
)

export const getEventCover = createSelector(
  getEvent,
  (state) => state,
  (event, state) => getFile(state, getObjId(event, 'cover')),
)

export const getEventFiles = createSelector(getEvent, getFiles, (event, files) =>
  files
    .filter((file) => file.attachable_type === 'events' && file.attachable_id === event.id)
    .toList()
    .sort((a, b) => {
      if (a.category === 'cover') return -1
      if (b.category === 'cover') return 1
      return a.order - b.order
    }),
)

export const getEventFood = createSelector(
  getEvent,
  (state) => state,
  (event, state) => getFood(state, getObjId(event, 'food')),
)

export const getEventAlcohols = createSelector(
  getEvent,
  (state) => state,
  (event, state) => event.alcohols.map((id) => getAlcohol(state, id)),
)

export const getEventPlace = createSelector(
  getEvent,
  (state) => state,
  (event, state) => getPlace(state, getObjId(event, 'place')),
)

export const getEventHostFiles = createSelector(getEventHost, getFiles, (host, files) =>
  files
    .filter((file) => file.account_id === (host.account_id || host.account))
    .toList()
    .sort((a, b) => a.order - b.order),
)

export const getEventDiets = createSelector(
  getEvent,
  (state) => state,
  (event, state) => event.diets.map((id) => getDiet(state, id)),
)

export const getEventHostAvatar = createSelector(
  getEventHost,
  (state) => state,
  (host, state) => getUserAvatar(state, host.id),
)

export const getEventDescriptionsInOrder = createSelector(
  getEvent,
  (state, id, describes, locale) => ({ state, describes, locale }),
  (event, { state, describes, locale }) => {
    const descriptions = event.descriptions.filter((d) => d.get('describes') === describes)
    const availableLanguages = descriptions
      .map((desc) => desc.get('language_id'))
      .toSet()
      .toList()
      .map((id) => getLanguage(state, id))
    const langId = (
      availableLanguages.find((l) => l.locale === locale) ||
      availableLanguages.find((l) => l.locale === 'en') ||
      availableLanguages.first() ||
      {}
    ).id
    if (!langId) return new List()
    return descriptions
      .filter((desc) => desc.get('language_id') === langId)
      .sortBy(
        (desc) => desc.get('order'),
        (o1, o2) => o1 - o2,
      )
  },
)

// Args: id, date
export const getEventOverrides = createSelector(
  getEvent,
  (state, id, date) => ({ state, id, date }),
  (event, { state, id, date }) => (date ? getOverride(state, `${id}-${date}`) : emptyOverride),
)

// Args: id, date
export const getEventWithOverrides = createSelector(getEvent, getEventOverrides, (event, overrides) => {
  if (overrides.date) return event.mergeWith((a, b) => (b === undefined ? a : b), overrides)
  return event
})

// loading
export const fetchingEvent = (state, id) => isFetching(`/events/${id}`)(state)

// errors
export const getFetchEventError = (state, id) => getFetchError(`/events/${id}`)(state)

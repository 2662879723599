import { ActionsCreator } from '../lib'
import { screenApi } from 'apis'
import { PartnerSchema } from 'redux/schemas'

export const partnersActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'partners' })

export const createPartner = (payload) =>
  partnersActions.create({
    url: '/partners',
    payload,
    schema: { partner: PartnerSchema },
  })

import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import { ScheduleSchema, BookingSchema, EventSchema, RequestSchema } from 'redux/schemas'

const defaultSchema = {
  planning: {
    schedule: [ScheduleSchema],
    bookings: [BookingSchema],
    requests: [RequestSchema],
    events: [EventSchema],
  },
}
export const schedulesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'schedules', defaultSchema })

export const fetchEventNextSchedule = (eventId) => schedulesActions.fetch({ url: `/events/${eventId}/planning/next` })

export const fetchEventMonthSchedules =
  (eventId, mDateStart, mDateEnd = undefined) =>
  (dispatch) => {
    const url = mDateEnd
      ? `/events/${eventId}/planning/${mDateStart.format('YYYY-MM')}/${mDateEnd.format('YYYY-MM')}`
      : `/events/${eventId}/planning/${mDateStart.format('YYYY-MM')}`
    return dispatch(schedulesActions.fetch({ url }))
  }

import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import { FileSchema } from 'redux/schemas'

export const filesActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'files' })

export const fetchEventFiles = (eventId, query, config) =>
  filesActions.fetch({
    url: `/events/${eventId}/files`,
    query,
    schema: { files: [FileSchema] },
    ...config,
  })

import { schema } from 'normalizr'
import { Record } from 'immutable'

export const CurrencySchema = new schema.Entity('currencies')
export const CurrencyRecord = Record({
  id: undefined,
  iso_3: '',
  symbol: '',
  title: '',
  decimal_point: undefined,
})

export const emptyCurrency = new CurrencyRecord()

import settings from 'settings'
import { vizeatUrls } from '@vizeat/helpers'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'

// TODO: this needs to be checked since in preview NODE_ENV is already "production"
export const urlFactory = vizeatUrls.vizeatUrlsFactory({
  config: { url: { host: settings.domainName }, env: process.env.NODE_ENV },
})

/**
 * Recursively decode a string input until either it's fully decoded or it's been 10 attempts
 * @param  {String} input     potentially encoded string
 * @param  {Number} [count=0] counter
 * @return {String}           decoded string, or last input if more than 10 attempts
 */
export function fullyDecode(input, count = 0) {
  if (!input) return null
  const decoded = decodeURIComponent(input)
  return decoded === input || count > 10 ? input : fullyDecode(decoded, ++count)
}

export function getOriginUrl() {
  if (typeof window === 'undefined') return

  if (window.location.origin) return window.location.origin
  const { protocol, hostname } = window.location

  return `${protocol}://${hostname}`
}

export function isCurrentPath(path, location) {
  const { search, pathname, query } = location

  if (typeof path === 'object') {
    const toOmit = ['page', 'c']
    return path.pathname === pathname && isEqual(omit(query, toOmit), omit(path.query, toOmit))
  }
  if (typeof path === 'string') {
    // Avoid currency in querystring
    // If query contains page data, consider only pathname
    // Else consider whole path, search included
    const searchString = search
      .replace(/[?&]c=[A-Z]{3}/, '')
      .replace(/[?&]page=[0-9]+/, '')
      .replace(/^&/, '?')
    return path === `${pathname}${searchString}`
  }
}

/**
 * Replace all possible format of dynamic routes (i.e. [id], [slug], [...slug], [[...slug]])
 * @param  {String} url     url containing dynamic path
 * @param  {String} value   the value to be replaced
 * @return {String}         url containing explicit dynamic path
 */
export function replaceDynamicPathByValue(url, value = '') {
  return url.replace(/\[{1,2}(?:\.{3})?(\w+)\]{1,2}/, value)
}

export function getURLWithSearchParams(url, searchParams) {
  return searchParams ? `${url}?${searchParams}` : url
}

import { createSelector } from 'reselect'
import { getEntitiesState } from './_utils'
import { Map } from 'immutable'

const getModals = createSelector(getEntitiesState, (entitiesState) => entitiesState.get('modals') || Map())

export const getIsLoginModalVisible = createSelector(getModals, (modals) => modals.get('login') || false)

export const getIsRegisterModalVisible = createSelector(getModals, (modals) => modals.get('register') || false)

export const getRegisterFormType = createSelector(getModals, (modals) => modals.get('registerType'))
